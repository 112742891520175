import m, * as bacta from "bacta"

import "@shoelace-style/shoelace/dist/themes/light.css"
import "@shoelace-style/shoelace/dist/themes/dark.css"
import "@shoelace-style/shoelace/dist/components/icon/icon.js"
import "@shoelace-style/shoelace/dist/components/icon-button/icon-button.js"
import { setBasePath } from "@shoelace-style/shoelace/dist/utilities/base-path.js"

export type IconAttrs = {
	name?: string | undefined
	src?: string | undefined
	label?: string
	library?: string
	slot?: string
	button?: object
} & bacta.Attributes

export type IconButtonAttrs = {
	href?: string | undefined
	target?: "_blank" | "_parent" | "_self" | "_top" | undefined
	download?: string | undefined
	disabled?: boolean
}

setBasePath("/sl")

export const Icon: bacta.ClosureComponent<IconAttrs> = () => {
	return {
		view: ({ attrs, children }) => {
			return m("sl-icon", attrs, children)
		},
	}
}

export const IconButton: bacta.ClosureComponent<
	IconButtonAttrs & IconAttrs
> = () => {
	return {
		view: ({ attrs, children }) => {
			return m("sl-icon-button", attrs, children)
		},
	}
}
