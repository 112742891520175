/* eslint-disable no-unsafe-optional-chaining */
import m, * as bacta from "bacta"
import { ConfirmPaymentData, StripeError, loadStripe } from "@stripe/stripe-js"
import { itemType } from "../../types"

type option = {
	layout?: "accordion" | "tabs"
	defaultValues: {
		billingDetails: {
			name: string
			email: string
			phone: string
			address?: {
				line1: string
				line2: string
				city: string
				state: string
				country: string
				postal_code: string
			}
		}
		paymentMethods?: { ideal: { bank: string } }
	}
	business?: { name: string }
	paymentMethodOrder?: string[]
	paymentMethodConfiguration?: string
	fields?: {
		billingDetails: {
			name: "never" | "auto"
			email: "never" | "auto"
			phone: "never" | "auto"
			address: "never" | "auto" | object
		}
	}
	readOnly?: boolean
	terms?: {
		applePay: "auto" | "always" | "never"
		auBecsDebit: "auto" | "always" | "never"
		bancontact: "auto" | "always" | "never"
		card: "auto" | "always" | "never"
		cashapp: "auto" | "always" | "never"
		googlePay: "auto" | "always" | "never"
		ideal: "auto" | "always" | "never"
		paypal: "auto" | "always" | "never"
		sepaDebit: "auto" | "always" | "never"
		sofort: "auto" | "always" | "never"
		usBankAccount: "auto" | "always" | "never"
	}
	wallets?: {
		applePay: "auto" | "never"
		googlePay: "auto" | "never"
	}
}

type StripeAttrs = {
	mode?: string
	appearance: {
		theme: "flat" | "stripe" | "night"
		variables?: object
	}
	amount: number
	options: Partial<option>
	currency: string
	error: any
	postop?: any
	status: bacta.Stream<string>
	address: bacta.Stream<any>
	order: bacta.Stream<itemType | null>
	writeToLoad: any
}

// layout - Layout for the Payment Element.
// defaultValues - Initial customer information to display in the Payment Element.
// business - Information about your business to display in the Payment Element.
// paymentMethodOrder - Order to list payment methods in.
// fields - Whether to display certain fields.
// readOnly - Whether payment details can be changed.
// terms - Whether mandates or other legal agreements are displayed in the Payment Element. The default behaviour is to show them only when necessary.
// wallets - Whether to show wallets such as Apple Pay or Google Pay. The default is to show them when possible.

export type MainStripeAttrs = StripeAttrs

export const HarthStripe: bacta.ClosureComponent<MainStripeAttrs> = () => ({
	async oncreate({ attrs }) {
		const { STRIPE_KEY, STRIPE_CONFIG } = await m.request<{
			STRIPE_KEY: string
			STRIPE_CONFIG: string
		}>("/api/stripe/config", {
			method: "GET",
		})

		const stripe = await loadStripe(STRIPE_KEY)
		if (!stripe) {
			console.error("Could not load stripe")
			return
		}

		const elements = stripe.elements({
			appearance: attrs.appearance,
			mode: "payment",
			amount: attrs.amount,
			currency: attrs.currency,
		})

		const paymentElement = elements.create("payment", {
			layout: { type: "accordion", defaultCollapsed: true },
			wallets: {
				applePay: "auto",
				googlePay: "auto",
			},
			paymentMethodConfiguration: STRIPE_CONFIG,
			...attrs.options,
		})

		if (paymentElement) {
			paymentElement.mount("#payment-element")
		}

		const submitBtn = document.getElementById("submit-pay") as HTMLButtonElement

		const handleError = (stripeError: StripeError) => {
			console.error(stripeError)
			if (submitBtn) {
				submitBtn.disabled = false
			}
		}

		submitBtn?.addEventListener("click", async (event) => {
			event.preventDefault()
			if (submitBtn?.disabled) {
				return
			}

			if (submitBtn) {
				submitBtn.disabled = true
			}

			// Trigger form validation and wallet collection
			const { error: submitError } = (await elements?.submit()) ?? {}
			if (submitError) {
				handleError(submitError)
				return
			}

			// Create the PaymentIntent and obtain clientSecret
			const { client_secret } = await m.request<{
				client_secret: string
			}>("/api/stripe/create-intent", {
				method: "POST",
				body: {
					amount: attrs.amount,
					description: !attrs.order.get()?.paid
						? "EVGEN initial"
						: "EVGEN variation",
					currency: attrs.currency,
					receipt_email: attrs.order.get()?.order_email,
				},
			})

			// Confirm the PaymentIntent using the details collected by the Payment Element
			const confirmedPayment = await stripe?.confirmPayment({
				elements: elements ?? undefined,
				clientSecret: client_secret,
				redirect: "if_required",
				confirmParams: {
					return_url: window.location.origin,
					shipping: {
						address: {
							line2: attrs.address.get()?.address?.district,
							line1: [
								attrs.address.get()?.address?.houseNumber,
								attrs.address.get()?.address?.street,
							].join(" "),
							city: attrs.address.get()?.address?.city,
							state: attrs.address.get()?.address?.state,
							country:
								attrs.address.get()?.address?.countryName == "Australia"
									? "AU"
									: "",
							postal_code: attrs.address.get()?.address?.postalCode,
						},
						name: attrs?.options?.defaultValues?.billingDetails.name,
						tracking_number: [
							attrs.order.get()?.order_id,
							!attrs.order.get()?.paid ? "initial" : "variation",
						].join("|"),
					},
				} as ConfirmPaymentData,
			})

			if (confirmedPayment && confirmedPayment.error) {
				attrs.error.update(() => confirmedPayment.error.message)
			} else {
				attrs.error.update(() => "")
				attrs.status.update(() => "Payment Completed")

				attrs.writeToLoad(true)
				await m
					.request<any[]>("/api/stripe/confirm-intent", {
						method: "POST",
						body: confirmedPayment,
					})
					.then(
						(res: any) => {
							return res
						},
						(e: any) => {
							console.log(e.response)
							return e.response.error == "Unauthorized call"
						}
					)

				attrs.writeToLoad(false)
				m.route.set(window.location.origin + "/questions/thankyou")
			}

			submitBtn.disabled = false
		})
	},
	view: () => {
		return m(
			"#payment-element",
			bacta.css`
				& {
					/* For some reason it introduces horizontal scrollbars*/
					width: 99%;
				}
			`
		)
	},
})
