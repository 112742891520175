/* eslint-disable no-mixed-spaces-and-tabs */
// todo-james paragraph isn't a regex, either rename module/function etc

/**
 * Formats a vlaue into money formats for human representation.
 *
 * e.g. prettyKey('500520') //=> $ 500,520.00
 */

const uniqPattern = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi
const moneyPattern = /(\d)(?=(\d{3})+\.)/g

function paragraphPattern(xs: TemplateStringsArray, ...ys: any[]) {
	return xs
		.reduce((p, n, i) => p + n + (ys[i] || ""), "")
		.replace(/\t|\n/g, "")
		.trim()
}

function pretty(key = "", replacements: Record<string, any> = {}) {
	let rkey = key
	Object.keys(replacements).map((k) => {
		rkey = rkey.replace(k, replacements[k])
	})
	return rkey
		.split("_")
		.map((k) => k[0].toUpperCase() + k.slice(1))
		.join(" ")
}
function progressValue({ lbound = 0, ubound = 100, current = 0 }) {
	return (
		Math.min(Math.max((current - lbound) / (ubound - lbound), 0), 100) * 100
	)
}

function nFormatter(num: string, digits: number, forceFromat: number) {
	const si = [
		{ value: 1, symbol: "" },
		{ value: 1e3, symbol: "k" },
		{ value: 1e6, symbol: "M" },
		{ value: 1e9, symbol: "G" },
		{ value: 1e12, symbol: "T" },
		{ value: 1e15, symbol: "P" },
		{ value: 1e18, symbol: "E" },
	]
	const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
	let i
	for (i = si.length - 1; i > 0; i--) {
		if (Number(num) >= si[i].value) {
			break
		}
	}
	return num.length < 9 && !forceFromat
		? num
		: (Number(num) / si[i].value).toFixed(digits).replace(rx, "$1") +
				si[i].symbol
}

export function money(n = 0, forceFromat = 0) {
	return isNaN(n)
		? ``
		: Number(n) < 0
		? "$ (" +
		  nFormatter(Number(Math.abs(n)).toFixed(2), 2, forceFromat).replace(
				moneyPattern,
				"$1,"
		  ) +
		  ")"
		: "$" +
		  nFormatter(Number(n).toFixed(2), 2, forceFromat).replace(
				moneyPattern,
				"$1,"
		  )
}

export { uniqPattern, paragraphPattern, pretty, progressValue }
