/* eslint-disable no-mixed-spaces-and-tabs */
// todo-james paragraph isn't a regex, either rename module/function etc

/**
 * Formats a vlaue into money formats for human representation.
 *
 * e.g. prettyKey('500520') //=> $ 500,520.00
 */
// import { Store } from "jaforbes-s/store.js";
import m, { Store } from "bacta"
import { FormalAddress, State } from "../store"
import { URLSearchParams } from "url"

async function setOrgOptions(store: Store<any>) {
	const token = ""
	// const contractors = await m.request<any[]>("/api/sql/contractors", {
	// 	method: "GET",
	// 	body: { tag: "sql" },
	// 	headers: {
	// 		Authorization: "Bearer " + token,
	// 	},
	// })
	const services = await m.request<any[]>("/api/odin/disciplines", {
		method: "GET",
		body: {
			user_id: store.prop("user").get().user_id,
			tag: "sql",
		},
		headers: {
			Authorization: "Bearer " + token,
		},
	})

	const sites = await m.request<any[]>("/api/odin/projects", {
		method: "GET",
		body: {
			user_id: store.prop("user").get().user_id,
			tag: "sql",
		},
		headers: {
			Authorization: "Bearer " + token,
		},
	})
	store.prop("sites").update(() => sites.map((i: any) => i.site_name))
	store.prop("services").update(() => services.map((i: any) => i.service_name))
}

async function searchServices(store: Store<any>, s = "") {
	const search = s

	const supplier = store.prop("supplier_name").get()
	const name = store.prop("supplier_items_name").get()
	const description = store.prop("supplier_items_description").get()

	const minweight = store.prop("supplier_items_weight_min").get()
	const minheight = store.prop("supplier_items_height_min").get()
	const minwdith = store.prop("supplier_items_wdith_min").get()
	const minlength = store.prop("supplier_items_length_min").get()
	const mincost = store.prop("supplier_locations_items_cost_min").get()

	const maxweight = store.prop("supplier_items_weight_max").get()
	const maxheight = store.prop("supplier_items_height_max").get()
	const maxwdith = store.prop("supplier_items_wdith_max").get()
	const maxlength = store.prop("supplier_items_length_max").get()
	const maxcost = store.prop("supplier_locations_items_cost_max").get()

	const industry = store.prop("supplier_items_industry").get()
	const specifications = store.prop("supplier_items_specifications").get()
	const features = store.prop("supplier_items_features").get()
	const within = store.prop("supplier_locations_address").get()
	const currentlocation = store.prop("ValidatedAddress").get()
	const currentlocationlat = currentlocation.lat || 0
	const currentlocationlng = currentlocation.lng || 0
	const available = store.prop("supplier_locations_items_available").get()
	const categories = await sql`
		with items as (
			select 
				*
				,calculate_distance(
					supplier_locations.supplier_locations_gps[0]
					, supplier_locations.supplier_locations_gps[1]
					, ${currentlocationlat}
					, ${currentlocationlng}
					, 'K'
				)					
			from app.supplier_locations_items
			left join app.supplier_items using (supplier_items_id)
			left join app.supplier_locations using (supplier_locations_id)				
			left join app.suppliers on app.suppliers.supplier_id = app.supplier_items.supplier_id 
		)
		,fitems as (
			select 
				*
			from items
			where ( (${minweight} = 0 and ${maxweight} = 0) or ( supplier_items_weight > ${minweight} and supplier_items_weight < ${maxweight} ) ) 
			and ( (${minheight} = 0 and ${maxheight} = 0) or ( supplier_items_height > ${minheight} and supplier_items_height < ${maxheight} ) ) 
			and ( (${minwdith} = 0 and ${maxwdith} = 0) or ( supplier_items_wdith > ${minwdith} and supplier_items_wdith < ${maxwdith} ) ) 
			and ( (${minlength} = 0 and ${maxlength} = 0) or ( supplier_items_length > ${minlength} and supplier_items_length < ${maxlength} ) ) 
			and ( (${mincost} = 0 and ${maxcost} = 0) or ( supplier_locations_items_cost > ${mincost} and supplier_locations_items_cost < ${maxcost}) ) 
			
			and ( ${industry} = '' or supplier_items_industry = ANY( ${industry} ) ) 

			and ( ${features} = '' or supplier_items_features @> ${features})

			and ( ${supplier} = '' or lower(supplier_name) LIKE lower(('%' || ${supplier} || '%')))
			and ( ${name} = ''  or lower(supplier_items_name) LIKE lower(('%' || ${name} || '%'))) 
			and ( ${description} = ''  or lower(supplier_items_description) LIKE lower(('%' || ${description} || '%')))
			and ( ${specifications} = ''  or lower(supplier_items_specifications) LIKE lower(('%' || ${specifications} || '%')))
			
			and ( ${available} = supplier_locations_items_available )
			and ( calculate_distance < ${within} or ${within} = 0)
			and ( 
				calculate_distance < 100 
				or (${currentlocation.lat}::float = 0 and ${currentlocation.lng}::float = 0)
			)
		)
		select 
			supplier_items_category
			,supplier_items_icon
			,count(distinct supplier_items_id)
		from app.services AS r
		where ( ${search} = '' or lower(r::text) LIKE lower(('%' || ${search} || '%')))
		group by supplier_items_category, supplier_items_icon	
	`

	store.prop("services").update(() => categories)
	store.prop("loading").update(() => false)
}

async function searchSites(store: Store<any>, s = "") {
	const basket = store.prop("basket").get()
	const category = store.prop("category").get()
	const search = s

	const supplier = store.prop("supplier_name").get()
	const name = store.prop("supplier_items_name").get()
	const description = store.prop("supplier_items_description").get()

	const minweight = store.prop("supplier_items_weight_min").get()
	const minheight = store.prop("supplier_items_height_min").get()
	const minwdith = store.prop("supplier_items_wdith_min").get()
	const minlength = store.prop("supplier_items_length_min").get()
	const mincost = store.prop("supplier_locations_items_cost_min").get()

	const maxweight = store.prop("supplier_items_weight_max").get()
	const maxheight = store.prop("supplier_items_height_max").get()
	const maxwdith = store.prop("supplier_items_wdith_max").get()
	const maxlength = store.prop("supplier_items_length_max").get()
	const maxcost = store.prop("supplier_locations_items_cost_max").get()

	const industry = store.prop("supplier_items_industry").get()
	const specifications = store.prop("supplier_items_specifications").get()
	const features = store.prop("supplier_items_features").get()
	const within = store.prop("supplier_locations_address").get()
	const currentlocation = store.prop("ValidatedAddress").get()
	const currentlocationlat = currentlocation.lat || 0
	const currentlocationlng = currentlocation.lng || 0
	const available = store.prop("supplier_locations_items_available").get()
	const sites = await sql`
		with checkout as (
			select * from json_to_recordset(${basket}::json )
			as x(
				"supplier_items_id" uuid,
				"supplier_locations_id" uuid,
				"quantity" real
			)												
		)
		,items as (
			select 
				* 
				,C.supplier_items_id as checked
				,SLI.supplier_items_id
				,SLI.supplier_locations_id
				,(case when C.quantity is null or C.quantity = 0 then 0 else C.quantity end) as quantity 
				,calculate_distance(
					SL.supplier_locations_gps[0]
					, SL.supplier_locations_gps[1]
					, ${currentlocationlat}
					, ${currentlocationlng}
					, 'K'
				)
			from app.supplier_locations_items SLI
			inner join app.supplier_items SI on  SI.supplier_items_id = SLI.supplier_items_id	
			inner join app.supplier_locations SL on SL.supplier_locations_id = SLI.supplier_locations_id	
			inner join app.suppliers S on SL.supplier_id = S.supplier_id
			left join checkout C on 
				SL.supplier_locations_id = C.supplier_locations_id	
				and SI.supplier_items_id = C.supplier_items_id					
		)
		select 
			*
		from items AS r
		where checked is not null
		or (( ${search} = '' or r::text LIKE ('%' || ${search} || '%') )
		and ( (${minweight} = 0 and ${maxweight} = 0) or ( supplier_items_weight > ${minweight} and supplier_items_weight < ${maxweight} ) ) 
		and ( (${minheight} = 0 and ${maxheight} = 0) or ( supplier_items_height > ${minheight} and supplier_items_height < ${maxheight} ) ) 
		and ( (${minwdith} = 0 and ${maxwdith} = 0) or ( supplier_items_wdith > ${minwdith} and supplier_items_wdith < ${maxwdith} ) ) 
		and ( (${minlength} = 0 and ${maxlength} = 0) or ( supplier_items_length > ${minlength} and supplier_items_length < ${maxlength} ) ) 
		and ( (${mincost} = 0 and ${maxcost} = 0) or ( supplier_locations_items_cost > ${mincost} and supplier_locations_items_cost < ${maxcost}) ) 
		
		and ( ${industry} = '' or supplier_items_industry = ANY( ${industry} ) ) 

		and ( ${features} = '' or supplier_items_features @> ${features})
		
		and ( ${supplier} = '' or lower(supplier_name) LIKE lower(('%' || ${supplier} || '%')))
		and ( ${name} = ''  or lower(supplier_items_name) LIKE lower(('%' || ${name} || '%'))) 
		and ( ${description} = ''  or lower(supplier_items_description) LIKE lower(('%' || ${description} || '%')))
		and ( ${specifications} = ''  or lower(supplier_items_specifications) LIKE lower(('%' || ${specifications} || '%')))
		
		and ( ${available} = supplier_locations_items_available )
		and ( supplier_items_category = ${category} or ${!category} = true)
		and ( calculate_distance < ${within} or ${within} = 0)
		and ( 
			calculate_distance < 100 
			or (${currentlocation.lat}::float = 0 and ${currentlocation.lng}::float = 0)
		))		
	`

	store.prop("sites").update(() => sites)
	store.prop("loading").update(() => false)
}

async function setMetaData(store: Store<any>, user: any) {
	if (user) {
		const AutoFillOptions: any[] = await sql`
			select
				"site_name"
			from app.sites
		`

		store.prop("AutoFillOptions").update(() => AutoFillOptions)
		store
			.prop("siteOptions")
			.update(() => [...new Set(AutoFillOptions.map((a) => a.site_name))])
		// store
		// 	.prop("phoneOptions")
		// 	.update(() => [...new Set(AutoFillOptions.map((a) => a.order_phone))])
		// store
		// 	.prop("nameOptions")
		// 	.update(() => [
		// 		...new Set(AutoFillOptions.map((a) => a.order_contact_name)),
		// 	])

		const [organization] =
			(await sql`select * from app.organizations where user_id = ${user.user_id}`) as Array<any>

		store.prop("organization").update(() => organization)

		// GET CONTRACTOR/TEAM DATA
		const [res] = await contractor(user.user_id)

		store.prop("contractor").update(() => res)

		await setOrgOptions(store)

		if (store.prop("siteOptions").get().length > 0) {
			store.prop("state").update(() => "Search")
		}

		return organization
	} else {
		store.prop("state").update(() => "")
		return { organization_id: null }
	}
}

async function initialize(store: Store<State>) {
	console.log("initialize")
	if (store.prop("initialized").get()) {
		return
	} else {
		store.prop("initialized").set(true)
	}
	store.prop("userload").update(() => true)
	try {
		const [user] = (await sql`

				select 
					user_id
					, email
					, name
					, picture
					, organization_id					
					, to_json(array_remove(array_agg( distinct UA.* ), null)) as users_addresses					
				from app.users U
				left join app.users_addresses UA using(user_id)
				group by U.user_id, U.email, U.name, U.picture, U.organization_id 
		`) as Array<any>

		store.prop("user").update(() => user)

		await setMetaData(store, user)

		await getLocation(store, store.prop("ValidatedAddress"))

		store.prop("userload").update(() => false)
		store.prop("loading").update(() => false)
	} catch (e) {
		// DELETE AFTER TESTING
		// store
		// 	.prop("ValidatedAddress")
		// 	.update(() => "14 Attunga Ave, Earlwood NSW 2206, Australia")
		// store.prop("order_contact_name").update(() => "Emmanuel")
		// store.prop("order_phone").update(() => "0421763259")
		// store.prop("order_email").update(() => "emmanuel@harth.io")
		// store.prop("order_description").update(() => "Front right side")
		// store.prop("workingdistance").update(() => 5)
		// // store.prop("order").update(() => "processing")
		// store.prop("separatedGarage").update(() => "NO")
		// store.prop("charge").update(() => "YES")
		// store.prop("order_status").update(() => "Contact Details Acquired")

		window.history.replaceState(null, "", "/installer")
		store.prop("state").update(() => "")
		store.prop("userload").update(() => false)
		store.prop("loading").update(() => false)
		return e
	}
	store.prop("userload").update(() => false)
	return null
}

async function getLocation(store: Store<any>, address: Store<any>) {
	const processing = store.prop("gettinglocation")
	const setlatlong = (s: any) => {
		address.update(() =>
			s
				? {
						lat: Number(s.coords.latitude),
						lng: Number(s.coords.longitude),
				  }
				: {}
		)
		processing.update(() => false)
		return false
	}

	// eslint-disable-next-line no-undef
	if (!navigator.geolocation) {
		return true
	} else {
		// eslint-disable-next-line no-undef
		processing.update(() => true)
		return navigator.geolocation.getCurrentPosition(setlatlong, setlatlong)
	}
}

async function stripe(intent: { amount: number; currency: string }) {
	return m
		.request<any[]>("/api/stripe/create-intent", {
			method: "POST",
			body: intent,
		})
		.then(
			(res: any) => {
				// console.log(res)
				return res
			},
			(e: any) => {
				return e.response.error == "Unauthorized call"
			}
		)
}

async function optout(retrieve: string) {
	return m
		.request<any[]>("/api/pricing/optout", {
			method: "POST",
			body: {
				retrieve,
			},
		})
		.then(
			(res: any) => {
				// console.log(res)
				return res
			},
			(e: any) => {
				console.log(e.response)
				return e.response.error == "Unauthorized call"
			}
		)
}

async function price(
	retrieve: string,
	packages: {
		workingdistance: number
		separatedGarage: string
		additionals: number
		concretePavers: string
		phase: string
		groundworkingdistance: number
		storey: number
		propertyowner: string
		aerial: string
		charger: number
		evlocation: string
	}[]
) {
	return m
		.request<any[]>("/api/pricing/price", {
			method: "POST",
			body: {
				data: packages,
				retrieve,
				pushMonday: true,
				pushOdin: true,
			},
		})
		.then(
			(res: any) => {
				// console.log(res)
				return res
			},
			(e: any) => {
				console.log(e.response)
				return e.response.error == "Unauthorized call"
			}
		)
}

function sql(query: TemplateStringsArray, ...args: any[]): Promise<any[]> {
	const token = ""
	return m.request<any[]>("/api/sql/select", {
		method: "POST",
		body: { query, args, tag: "sql" },
		headers: {
			Authorization: "Bearer " + token,
		},
	})
}

async function contractor(user_id: string) {
	const token = ""
	return m.request<any[]>(`/api/odin/contractors?user_id=${user_id}`, {
		method: "GET",
		headers: {
			Authorization: "Bearer " + token,
		},
	})
}

async function works(user_id: string) {
	const token = ""
	const orders =
		await sql`select project_id from orders where user_id = ${user_id}`
	return m.request<any[]>(
		`/api/odin/projects?
			projects=${orders.map((o) => o.project_id).join(",")}`,
		{
			method: "GET",
			headers: {
				Authorization: "Bearer " + token,
			},
		}
	)
}

async function consent({
	user_id,
	email,
	id,
	set,
	del,
}: {
	user_id: string
	email: string
	id: string
	set: boolean
	del: boolean
}) {
	const token = ""
	const params = [
		{ n: "user_id", v: user_id },
		{ n: "email", v: email },
		{ n: "id", v: id },
		{ n: "set", v: set },
		{ n: "del", v: del },
	]
		.map((p) => (p.v ? p.n + "=" + p.v : ""))
		.filter((p) => p)
		.join("&")

	return m
		.request<any[]>(`/api/auth/consent?` + params, {
			method: "GET",
			headers: {
				Authorization: "Bearer " + token,
			},
		})
		.then(
			(res: any) => {
				// console.log(res)
				return res
			},
			(e: any) => {
				console.log(e.response)
				return e
			}
		)
}

function here(address = "" as string): Promise<any[]> {
	return address
		? m
				.request("/api/here/address", {
					method: "POST",
					body: {
						address,
						// , types: "houseNumber"
					},
				})
				.then(
					(res: any) => {
						// console.log(res)
						return res
					},
					(e: any) => {
						console.log(e.response.error)
						return e.response.error == "Unauthorized call"
					}
				)
		: ({} as any)
}

export {
	sql,
	price,
	initialize,
	searchServices,
	searchSites,
	here,
	getLocation,
	stripe,
	works,
	consent,
	optout,
}
