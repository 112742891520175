import m, * as bacta from "bacta"
import css from "../../main.module.css"

import "@shoelace-style/shoelace/dist/themes/light.css"
import "@shoelace-style/shoelace/dist/themes/dark.css"
import "@shoelace-style/shoelace/dist/components/divider/divider.js"

export type DividerAttrs = {
	vertical?: boolean
}

export const Divider: bacta.ClosureComponent<DividerAttrs> = () => {
	return {
		view: ({ attrs, children }) => {
			return m("sl-divider", attrs, children)
		},
	}
}
