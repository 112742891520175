import m, * as bacta from "bacta"
import { SpinnerAttrs, Spinner } from "../sl/spinner.js"
// import * as Store from "jaforbes-s/store.js";

export type Attrs = {
	size?: string
	color?: string
}

type BaseSpinnerAttrs = {
	sl?: Partial<SpinnerAttrs>
}

export type MainSpinnerAttrs = BaseSpinnerAttrs & Attrs

export const HarthSpinner: bacta.ClosureComponent<MainSpinnerAttrs> = () => ({
	view: ({ attrs: { sl = {}, size, color }, children = [] }) => {
		return m(
			Spinner,
			{
				...sl,
				style: {
					[size ? "font-size" : ""]: size,
					[color ? "--indicator-color" : ""]: color,
				},
			},
			children
		)
	},
})
