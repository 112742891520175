import m, * as bacta from "bacta"
import { Divider, DividerAttrs } from "../sl/divider.js"

export type BaseAttrs = {
	sl?: Partial<DividerAttrs>
	width?: string
	centered?: boolean
}

export type MainAttrs = BaseAttrs

export const HarthDivider: bacta.ClosureComponent<MainAttrs> = () => ({
	view: ({ attrs: { sl = {}, centered, width } }) => {
		return m(Divider, {
			...sl,
			style: centered
				? {
						"margin-left": "auto",
						"margin-right": "auto",
						width: width || "10em",
				  }
				: {},
		})
	},
})
