import m, * as bacta from "bacta"

import "@shoelace-style/shoelace/dist/themes/light.css"
import "@shoelace-style/shoelace/dist/themes/dark.css"
import "@shoelace-style/shoelace/dist/components/spinner/spinner.js"

export type SpinnerAttrs = bacta.Attributes

export const Spinner: bacta.ClosureComponent<SpinnerAttrs> = () => {
	return {
		view: ({ attrs, children }) => {
			return m("sl-spinner", attrs, children)
		},
	}
}
