import m, * as bacta from "bacta"
import { ButtonAttrs, Button } from "../sl/button.js"

type Attrs = ButtonAttrs & {
	href: ButtonAttrs["href"]
}

export const Link = bacta.component<Attrs>(
	() => (v) =>
		m(
			Button,
			{
				onclick: (e: any) => {
					e.preventDefault()
					m.route.set(e.target.href)
				},
				...v.attrs,
			},
			bacta.css`
				&[disabled] {
					pointer-events: none;
				}
			`,
			v.children
		)
)
