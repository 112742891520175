import { Checkbox, CheckboxAttrs } from "../sl/checkbox"
import m, { BactaComponent, Store } from "bacta"

type Attrs = {
	query: Store<boolean | null>
}

type BaseAttrs = {
	sl?: Partial<CheckboxAttrs>
}

export type MainAttrs = BaseAttrs & Attrs

export const HarthCheckbox: BactaComponent<MainAttrs> = () => {
	return ({ attrs: { sl = {}, ...attrs } }) => {
		return m(Checkbox, {
			["onsl-change"]: (e: any) => {
				const v = (e.target as HTMLInputElement)?.checked
				if (v != null || v != undefined) {
					attrs.query.update(() => v)
				}
			},
			...sl,
			value: attrs.query.get(),
		})
	}
}
