import m, * as bacta from "bacta"

import "@shoelace-style/shoelace/dist/themes/light.css"
import "@shoelace-style/shoelace/dist/themes/dark.css"
import "@shoelace-style/shoelace/dist/components/dialog/dialog.js"

export type DialogAttrs = {
	open: boolean
	name?: string
	label?: string
	noHeader?: boolean
	"onsl-show"?: () => void
	"onsl-after-show"?: () => void
	"onsl-hide"?: () => void
	"onsl-after-hide"?: () => void
	"onsl-initial-focus"?: () => void
	"onsl-request-close"?: (event: {
		source: "close-button" | "keyboard" | "overlay"
	}) => void
} & bacta.Attributes

export const Dialog: bacta.ClosureComponent<DialogAttrs> = () => {
	return {
		view: ({ attrs, children }) => {
			return m("sl-dialog", attrs, children)
		},
	}
}
