export const termsOfService = `<html
xmlns:v="urn:schemas-microsoft-com:vml"
xmlns:o="urn:schemas-microsoft-com:office:office"
xmlns:w="urn:schemas-microsoft-com:office:word"
xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
xmlns="http://www.w3.org/TR/REC-html40"
>
<head>
	<meta http-equiv="Content-Type" content="text/html; charset=windows-1252" />
	<meta name="ProgId" content="Word.Document" />
	<meta name="Generator" content="Microsoft Word 15" />
	<meta name="Originator" content="Microsoft Word 15" />
	<link rel="File-List" href="EVGEN%20T&amp;Cs_files/filelist.xml" />
	<!--[if gte mso 9
		]><xml>
			<o:DocumentProperties>
				<o:Author>Emmanuel John</o:Author>
				<o:Template>Normal</o:Template>
				<o:LastAuthor>Emmanuel John</o:LastAuthor>
				<o:Revision>2</o:Revision>
				<o:TotalTime>27</o:TotalTime>
				<o:Created>2024-03-05T06:01:00Z</o:Created>
				<o:LastSaved>2024-03-05T06:01:00Z</o:LastSaved>
				<o:Pages>1</o:Pages>
				<o:Words>1777</o:Words>
				<o:Characters>10131</o:Characters>
				<o:Lines>84</o:Lines>
				<o:Paragraphs>23</o:Paragraphs>
				<o:CharactersWithSpaces>11885</o:CharactersWithSpaces>
				<o:Version>16.00</o:Version>
			</o:DocumentProperties>
			<o:OfficeDocumentSettings>
				<o:AllowPNG />
			</o:OfficeDocumentSettings> </xml
	><![endif]-->
	<link rel="themeData" href="EVGEN%20T&amp;Cs_files/themedata.thmx" />
	<link
		rel="colorSchemeMapping"
		href="EVGEN%20T&amp;Cs_files/colorschememapping.xml"
	/>
	<!--[if gte mso 9
		]><xml>
			<w:WordDocument>
				<w:SpellingState>Clean</w:SpellingState>
				<w:GrammarState>Clean</w:GrammarState>
				<w:TrackMoves>false</w:TrackMoves>
				<w:TrackFormatting />
				<w:PunctuationKerning />
				<w:ValidateAgainstSchemas />
				<w:SaveIfXMLInvalid>false</w:SaveIfXMLInvalid>
				<w:IgnoreMixedContent>false</w:IgnoreMixedContent>
				<w:AlwaysShowPlaceholderText>false</w:AlwaysShowPlaceholderText>
				<w:DoNotPromoteQF />
				<w:LidThemeOther>EN-AU</w:LidThemeOther>
				<w:LidThemeAsian>X-NONE</w:LidThemeAsian>
				<w:LidThemeComplexScript>X-NONE</w:LidThemeComplexScript>
				<w:Compatibility>
					<w:BreakWrappedTables />
					<w:SnapToGridInCell />
					<w:WrapTextWithPunct />
					<w:UseAsianBreakRules />
					<w:DontGrowAutofit />
					<w:SplitPgBreakAndParaMark />
					<w:EnableOpenTypeKerning />
					<w:DontFlipMirrorIndents />
					<w:OverrideTableStyleHps />
				</w:Compatibility>
				<m:mathPr>
					<m:mathFont m:val="Cambria Math" />
					<m:brkBin m:val="before" />
					<m:brkBinSub m:val="&#45;-" />
					<m:smallFrac m:val="off" />
					<m:dispDef />
					<m:lMargin m:val="0" />
					<m:rMargin m:val="0" />
					<m:defJc m:val="centerGroup" />
					<m:wrapIndent m:val="1440" />
					<m:intLim m:val="subSup" />
					<m:naryLim m:val="undOvr" /> </m:mathPr
			></w:WordDocument> </xml
	><![endif]-->
	<!--[if gte mso 9
		]><xml>
			<w:LatentStyles
				DefLockedState="false"
				DefUnhideWhenUsed="false"
				DefSemiHidden="false"
				DefQFormat="false"
				DefPriority="99"
				LatentStyleCount="376"
			>
				<w:LsdException
					Locked="false"
					Priority="0"
					QFormat="true"
					Name="Normal"
				/>
				<w:LsdException
					Locked="false"
					Priority="9"
					QFormat="true"
					Name="heading 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="9"
					SemiHidden="true"
					UnhideWhenUsed="true"
					QFormat="true"
					Name="heading 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="9"
					SemiHidden="true"
					UnhideWhenUsed="true"
					QFormat="true"
					Name="heading 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="9"
					SemiHidden="true"
					UnhideWhenUsed="true"
					QFormat="true"
					Name="heading 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="9"
					SemiHidden="true"
					UnhideWhenUsed="true"
					QFormat="true"
					Name="heading 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="9"
					SemiHidden="true"
					UnhideWhenUsed="true"
					QFormat="true"
					Name="heading 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="9"
					SemiHidden="true"
					UnhideWhenUsed="true"
					QFormat="true"
					Name="heading 7"
				/>
				<w:LsdException
					Locked="false"
					Priority="9"
					SemiHidden="true"
					UnhideWhenUsed="true"
					QFormat="true"
					Name="heading 8"
				/>
				<w:LsdException
					Locked="false"
					Priority="9"
					SemiHidden="true"
					UnhideWhenUsed="true"
					QFormat="true"
					Name="heading 9"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="index 1"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="index 2"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="index 3"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="index 4"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="index 5"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="index 6"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="index 7"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="index 8"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="index 9"
				/>
				<w:LsdException
					Locked="false"
					Priority="39"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="toc 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="39"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="toc 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="39"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="toc 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="39"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="toc 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="39"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="toc 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="39"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="toc 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="39"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="toc 7"
				/>
				<w:LsdException
					Locked="false"
					Priority="39"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="toc 8"
				/>
				<w:LsdException
					Locked="false"
					Priority="39"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="toc 9"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Normal Indent"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="footnote text"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="annotation text"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="header"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="footer"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="index heading"
				/>
				<w:LsdException
					Locked="false"
					Priority="35"
					SemiHidden="true"
					UnhideWhenUsed="true"
					QFormat="true"
					Name="caption"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="table of figures"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="envelope address"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="envelope return"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="footnote reference"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="annotation reference"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="line number"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="page number"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="endnote reference"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="endnote text"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="table of authorities"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="macro"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="toa heading"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="List"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="List Bullet"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="List Number"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="List 2"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="List 3"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="List 4"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="List 5"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="List Bullet 2"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="List Bullet 3"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="List Bullet 4"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="List Bullet 5"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="List Number 2"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="List Number 3"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="List Number 4"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="List Number 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="10"
					QFormat="true"
					Name="Title"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Closing"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Signature"
				/>
				<w:LsdException
					Locked="false"
					Priority="1"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Default Paragraph Font"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Body Text"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Body Text Indent"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="List Continue"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="List Continue 2"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="List Continue 3"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="List Continue 4"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="List Continue 5"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Message Header"
				/>
				<w:LsdException
					Locked="false"
					Priority="11"
					QFormat="true"
					Name="Subtitle"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Salutation"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Date"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Body Text First Indent"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Body Text First Indent 2"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Note Heading"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Body Text 2"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Body Text 3"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Body Text Indent 2"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Body Text Indent 3"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Block Text"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Hyperlink"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="FollowedHyperlink"
				/>
				<w:LsdException
					Locked="false"
					Priority="22"
					QFormat="true"
					Name="Strong"
				/>
				<w:LsdException
					Locked="false"
					Priority="20"
					QFormat="true"
					Name="Emphasis"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Document Map"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Plain Text"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="E-mail Signature"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="HTML Top of Form"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="HTML Bottom of Form"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Normal (Web)"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="HTML Acronym"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="HTML Address"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="HTML Cite"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="HTML Code"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="HTML Definition"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="HTML Keyboard"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="HTML Preformatted"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="HTML Sample"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="HTML Typewriter"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="HTML Variable"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Normal Table"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="annotation subject"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="No List"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Outline List 1"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Outline List 2"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Outline List 3"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Simple 1"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Simple 2"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Simple 3"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Classic 1"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Classic 2"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Classic 3"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Classic 4"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Colorful 1"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Colorful 2"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Colorful 3"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Columns 1"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Columns 2"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Columns 3"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Columns 4"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Columns 5"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Grid 1"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Grid 2"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Grid 3"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Grid 4"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Grid 5"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Grid 6"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Grid 7"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Grid 8"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table List 1"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table List 2"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table List 3"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table List 4"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table List 5"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table List 6"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table List 7"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table List 8"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table 3D effects 1"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table 3D effects 2"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table 3D effects 3"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Contemporary"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Elegant"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Professional"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Subtle 1"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Subtle 2"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Web 1"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Web 2"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Web 3"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Balloon Text"
				/>
				<w:LsdException Locked="false" Priority="39" Name="Table Grid" />
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Table Theme"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					Name="Placeholder Text"
				/>
				<w:LsdException
					Locked="false"
					Priority="1"
					QFormat="true"
					Name="No Spacing"
				/>
				<w:LsdException Locked="false" Priority="60" Name="Light Shading" />
				<w:LsdException Locked="false" Priority="61" Name="Light List" />
				<w:LsdException Locked="false" Priority="62" Name="Light Grid" />
				<w:LsdException
					Locked="false"
					Priority="63"
					Name="Medium Shading 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="64"
					Name="Medium Shading 2"
				/>
				<w:LsdException Locked="false" Priority="65" Name="Medium List 1" />
				<w:LsdException Locked="false" Priority="66" Name="Medium List 2" />
				<w:LsdException Locked="false" Priority="67" Name="Medium Grid 1" />
				<w:LsdException Locked="false" Priority="68" Name="Medium Grid 2" />
				<w:LsdException Locked="false" Priority="69" Name="Medium Grid 3" />
				<w:LsdException Locked="false" Priority="70" Name="Dark List" />
				<w:LsdException
					Locked="false"
					Priority="71"
					Name="Colorful Shading"
				/>
				<w:LsdException Locked="false" Priority="72" Name="Colorful List" />
				<w:LsdException Locked="false" Priority="73" Name="Colorful Grid" />
				<w:LsdException
					Locked="false"
					Priority="60"
					Name="Light Shading Accent 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="61"
					Name="Light List Accent 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="62"
					Name="Light Grid Accent 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="63"
					Name="Medium Shading 1 Accent 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="64"
					Name="Medium Shading 2 Accent 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="65"
					Name="Medium List 1 Accent 1"
				/>
				<w:LsdException Locked="false" SemiHidden="true" Name="Revision" />
				<w:LsdException
					Locked="false"
					Priority="34"
					QFormat="true"
					Name="List Paragraph"
				/>
				<w:LsdException
					Locked="false"
					Priority="29"
					QFormat="true"
					Name="Quote"
				/>
				<w:LsdException
					Locked="false"
					Priority="30"
					QFormat="true"
					Name="Intense Quote"
				/>
				<w:LsdException
					Locked="false"
					Priority="66"
					Name="Medium List 2 Accent 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="67"
					Name="Medium Grid 1 Accent 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="68"
					Name="Medium Grid 2 Accent 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="69"
					Name="Medium Grid 3 Accent 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="70"
					Name="Dark List Accent 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="71"
					Name="Colorful Shading Accent 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="72"
					Name="Colorful List Accent 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="73"
					Name="Colorful Grid Accent 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="60"
					Name="Light Shading Accent 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="61"
					Name="Light List Accent 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="62"
					Name="Light Grid Accent 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="63"
					Name="Medium Shading 1 Accent 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="64"
					Name="Medium Shading 2 Accent 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="65"
					Name="Medium List 1 Accent 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="66"
					Name="Medium List 2 Accent 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="67"
					Name="Medium Grid 1 Accent 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="68"
					Name="Medium Grid 2 Accent 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="69"
					Name="Medium Grid 3 Accent 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="70"
					Name="Dark List Accent 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="71"
					Name="Colorful Shading Accent 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="72"
					Name="Colorful List Accent 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="73"
					Name="Colorful Grid Accent 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="60"
					Name="Light Shading Accent 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="61"
					Name="Light List Accent 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="62"
					Name="Light Grid Accent 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="63"
					Name="Medium Shading 1 Accent 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="64"
					Name="Medium Shading 2 Accent 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="65"
					Name="Medium List 1 Accent 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="66"
					Name="Medium List 2 Accent 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="67"
					Name="Medium Grid 1 Accent 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="68"
					Name="Medium Grid 2 Accent 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="69"
					Name="Medium Grid 3 Accent 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="70"
					Name="Dark List Accent 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="71"
					Name="Colorful Shading Accent 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="72"
					Name="Colorful List Accent 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="73"
					Name="Colorful Grid Accent 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="60"
					Name="Light Shading Accent 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="61"
					Name="Light List Accent 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="62"
					Name="Light Grid Accent 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="63"
					Name="Medium Shading 1 Accent 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="64"
					Name="Medium Shading 2 Accent 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="65"
					Name="Medium List 1 Accent 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="66"
					Name="Medium List 2 Accent 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="67"
					Name="Medium Grid 1 Accent 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="68"
					Name="Medium Grid 2 Accent 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="69"
					Name="Medium Grid 3 Accent 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="70"
					Name="Dark List Accent 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="71"
					Name="Colorful Shading Accent 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="72"
					Name="Colorful List Accent 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="73"
					Name="Colorful Grid Accent 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="60"
					Name="Light Shading Accent 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="61"
					Name="Light List Accent 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="62"
					Name="Light Grid Accent 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="63"
					Name="Medium Shading 1 Accent 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="64"
					Name="Medium Shading 2 Accent 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="65"
					Name="Medium List 1 Accent 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="66"
					Name="Medium List 2 Accent 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="67"
					Name="Medium Grid 1 Accent 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="68"
					Name="Medium Grid 2 Accent 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="69"
					Name="Medium Grid 3 Accent 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="70"
					Name="Dark List Accent 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="71"
					Name="Colorful Shading Accent 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="72"
					Name="Colorful List Accent 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="73"
					Name="Colorful Grid Accent 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="60"
					Name="Light Shading Accent 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="61"
					Name="Light List Accent 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="62"
					Name="Light Grid Accent 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="63"
					Name="Medium Shading 1 Accent 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="64"
					Name="Medium Shading 2 Accent 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="65"
					Name="Medium List 1 Accent 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="66"
					Name="Medium List 2 Accent 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="67"
					Name="Medium Grid 1 Accent 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="68"
					Name="Medium Grid 2 Accent 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="69"
					Name="Medium Grid 3 Accent 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="70"
					Name="Dark List Accent 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="71"
					Name="Colorful Shading Accent 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="72"
					Name="Colorful List Accent 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="73"
					Name="Colorful Grid Accent 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="19"
					QFormat="true"
					Name="Subtle Emphasis"
				/>
				<w:LsdException
					Locked="false"
					Priority="21"
					QFormat="true"
					Name="Intense Emphasis"
				/>
				<w:LsdException
					Locked="false"
					Priority="31"
					QFormat="true"
					Name="Subtle Reference"
				/>
				<w:LsdException
					Locked="false"
					Priority="32"
					QFormat="true"
					Name="Intense Reference"
				/>
				<w:LsdException
					Locked="false"
					Priority="33"
					QFormat="true"
					Name="Book Title"
				/>
				<w:LsdException
					Locked="false"
					Priority="37"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Bibliography"
				/>
				<w:LsdException
					Locked="false"
					Priority="39"
					SemiHidden="true"
					UnhideWhenUsed="true"
					QFormat="true"
					Name="TOC Heading"
				/>
				<w:LsdException Locked="false" Priority="41" Name="Plain Table 1" />
				<w:LsdException Locked="false" Priority="42" Name="Plain Table 2" />
				<w:LsdException Locked="false" Priority="43" Name="Plain Table 3" />
				<w:LsdException Locked="false" Priority="44" Name="Plain Table 4" />
				<w:LsdException Locked="false" Priority="45" Name="Plain Table 5" />
				<w:LsdException
					Locked="false"
					Priority="40"
					Name="Grid Table Light"
				/>
				<w:LsdException
					Locked="false"
					Priority="46"
					Name="Grid Table 1 Light"
				/>
				<w:LsdException Locked="false" Priority="47" Name="Grid Table 2" />
				<w:LsdException Locked="false" Priority="48" Name="Grid Table 3" />
				<w:LsdException Locked="false" Priority="49" Name="Grid Table 4" />
				<w:LsdException
					Locked="false"
					Priority="50"
					Name="Grid Table 5 Dark"
				/>
				<w:LsdException
					Locked="false"
					Priority="51"
					Name="Grid Table 6 Colorful"
				/>
				<w:LsdException
					Locked="false"
					Priority="52"
					Name="Grid Table 7 Colorful"
				/>
				<w:LsdException
					Locked="false"
					Priority="46"
					Name="Grid Table 1 Light Accent 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="47"
					Name="Grid Table 2 Accent 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="48"
					Name="Grid Table 3 Accent 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="49"
					Name="Grid Table 4 Accent 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="50"
					Name="Grid Table 5 Dark Accent 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="51"
					Name="Grid Table 6 Colorful Accent 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="52"
					Name="Grid Table 7 Colorful Accent 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="46"
					Name="Grid Table 1 Light Accent 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="47"
					Name="Grid Table 2 Accent 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="48"
					Name="Grid Table 3 Accent 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="49"
					Name="Grid Table 4 Accent 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="50"
					Name="Grid Table 5 Dark Accent 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="51"
					Name="Grid Table 6 Colorful Accent 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="52"
					Name="Grid Table 7 Colorful Accent 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="46"
					Name="Grid Table 1 Light Accent 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="47"
					Name="Grid Table 2 Accent 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="48"
					Name="Grid Table 3 Accent 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="49"
					Name="Grid Table 4 Accent 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="50"
					Name="Grid Table 5 Dark Accent 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="51"
					Name="Grid Table 6 Colorful Accent 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="52"
					Name="Grid Table 7 Colorful Accent 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="46"
					Name="Grid Table 1 Light Accent 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="47"
					Name="Grid Table 2 Accent 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="48"
					Name="Grid Table 3 Accent 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="49"
					Name="Grid Table 4 Accent 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="50"
					Name="Grid Table 5 Dark Accent 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="51"
					Name="Grid Table 6 Colorful Accent 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="52"
					Name="Grid Table 7 Colorful Accent 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="46"
					Name="Grid Table 1 Light Accent 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="47"
					Name="Grid Table 2 Accent 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="48"
					Name="Grid Table 3 Accent 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="49"
					Name="Grid Table 4 Accent 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="50"
					Name="Grid Table 5 Dark Accent 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="51"
					Name="Grid Table 6 Colorful Accent 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="52"
					Name="Grid Table 7 Colorful Accent 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="46"
					Name="Grid Table 1 Light Accent 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="47"
					Name="Grid Table 2 Accent 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="48"
					Name="Grid Table 3 Accent 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="49"
					Name="Grid Table 4 Accent 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="50"
					Name="Grid Table 5 Dark Accent 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="51"
					Name="Grid Table 6 Colorful Accent 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="52"
					Name="Grid Table 7 Colorful Accent 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="46"
					Name="List Table 1 Light"
				/>
				<w:LsdException Locked="false" Priority="47" Name="List Table 2" />
				<w:LsdException Locked="false" Priority="48" Name="List Table 3" />
				<w:LsdException Locked="false" Priority="49" Name="List Table 4" />
				<w:LsdException
					Locked="false"
					Priority="50"
					Name="List Table 5 Dark"
				/>
				<w:LsdException
					Locked="false"
					Priority="51"
					Name="List Table 6 Colorful"
				/>
				<w:LsdException
					Locked="false"
					Priority="52"
					Name="List Table 7 Colorful"
				/>
				<w:LsdException
					Locked="false"
					Priority="46"
					Name="List Table 1 Light Accent 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="47"
					Name="List Table 2 Accent 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="48"
					Name="List Table 3 Accent 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="49"
					Name="List Table 4 Accent 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="50"
					Name="List Table 5 Dark Accent 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="51"
					Name="List Table 6 Colorful Accent 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="52"
					Name="List Table 7 Colorful Accent 1"
				/>
				<w:LsdException
					Locked="false"
					Priority="46"
					Name="List Table 1 Light Accent 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="47"
					Name="List Table 2 Accent 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="48"
					Name="List Table 3 Accent 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="49"
					Name="List Table 4 Accent 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="50"
					Name="List Table 5 Dark Accent 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="51"
					Name="List Table 6 Colorful Accent 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="52"
					Name="List Table 7 Colorful Accent 2"
				/>
				<w:LsdException
					Locked="false"
					Priority="46"
					Name="List Table 1 Light Accent 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="47"
					Name="List Table 2 Accent 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="48"
					Name="List Table 3 Accent 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="49"
					Name="List Table 4 Accent 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="50"
					Name="List Table 5 Dark Accent 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="51"
					Name="List Table 6 Colorful Accent 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="52"
					Name="List Table 7 Colorful Accent 3"
				/>
				<w:LsdException
					Locked="false"
					Priority="46"
					Name="List Table 1 Light Accent 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="47"
					Name="List Table 2 Accent 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="48"
					Name="List Table 3 Accent 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="49"
					Name="List Table 4 Accent 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="50"
					Name="List Table 5 Dark Accent 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="51"
					Name="List Table 6 Colorful Accent 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="52"
					Name="List Table 7 Colorful Accent 4"
				/>
				<w:LsdException
					Locked="false"
					Priority="46"
					Name="List Table 1 Light Accent 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="47"
					Name="List Table 2 Accent 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="48"
					Name="List Table 3 Accent 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="49"
					Name="List Table 4 Accent 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="50"
					Name="List Table 5 Dark Accent 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="51"
					Name="List Table 6 Colorful Accent 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="52"
					Name="List Table 7 Colorful Accent 5"
				/>
				<w:LsdException
					Locked="false"
					Priority="46"
					Name="List Table 1 Light Accent 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="47"
					Name="List Table 2 Accent 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="48"
					Name="List Table 3 Accent 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="49"
					Name="List Table 4 Accent 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="50"
					Name="List Table 5 Dark Accent 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="51"
					Name="List Table 6 Colorful Accent 6"
				/>
				<w:LsdException
					Locked="false"
					Priority="52"
					Name="List Table 7 Colorful Accent 6"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Mention"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Smart Hyperlink"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Hashtag"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Unresolved Mention"
				/>
				<w:LsdException
					Locked="false"
					SemiHidden="true"
					UnhideWhenUsed="true"
					Name="Smart Link"
				/>
			</w:LatentStyles> </xml
	><![endif]-->
	<style>
		<!--
		 /* Font Definitions */
		 @font-face
			{font-family:Wingdings;
			panose-1:5 0 0 0 0 0 0 0 0 0;
			mso-font-charset:2;
			mso-generic-font-family:auto;
			mso-font-pitch:variable;
			mso-font-signature:0 268435456 0 0 -2147483648 0;}
		@font-face
			{font-family:"Cambria Math";
			panose-1:2 4 5 3 5 4 6 3 2 4;
			mso-font-charset:0;
			mso-generic-font-family:roman;
			mso-font-pitch:variable;
			mso-font-signature:-536869121 1107305727 33554432 0 415 0;}
		@font-face
			{font-family:Aptos;
			mso-font-charset:0;
			mso-generic-font-family:swiss;
			mso-font-pitch:variable;
			mso-font-signature:536871559 3 0 0 415 0;}
		@font-face
			{font-family:Poppins;
			mso-font-charset:0;
			mso-generic-font-family:auto;
			mso-font-pitch:variable;
			mso-font-signature:32775 0 0 0 147 0;}
		@font-face
			{font-family:"Century Gothic";
			panose-1:2 11 5 2 2 2 2 2 2 4;
			mso-font-charset:0;
			mso-generic-font-family:swiss;
			mso-font-pitch:variable;
			mso-font-signature:647 0 0 0 159 0;}
		 /* Style Definitions */
		 p.MsoNormal, li.MsoNormal, div.MsoNormal
			{mso-style-unhide:no;
			mso-style-qformat:yes;
			mso-style-parent:"";
			margin-top:0cm;
			margin-right:0cm;
			margin-bottom:8.0pt;
			margin-left:0cm;
			line-height:107%;
			mso-pagination:widow-orphan;
			font-size:11.0pt;
			font-family:"Aptos",sans-serif;
			mso-ascii-font-family:Aptos;
			mso-ascii-theme-font:minor-latin;
			mso-fareast-font-family:Aptos;
			mso-fareast-theme-font:minor-latin;
			mso-hansi-font-family:Aptos;
			mso-hansi-theme-font:minor-latin;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:minor-bidi;
			mso-font-kerning:1.0pt;
			mso-ligatures:standardcontextual;
			mso-fareast-language:EN-US;}
		h1
			{mso-style-priority:9;
			mso-style-unhide:no;
			mso-style-qformat:yes;
			mso-style-link:"Heading 1 Char";
			mso-style-next:Normal;
			margin-top:18.0pt;
			margin-right:0cm;
			margin-bottom:4.0pt;
			margin-left:0cm;
			line-height:107%;
			mso-pagination:widow-orphan lines-together;
			page-break-after:avoid;
			mso-outline-level:1;
			font-size:20.0pt;
			font-family:"Aptos Display",sans-serif;
			mso-ascii-font-family:"Aptos Display";
			mso-ascii-theme-font:major-latin;
			mso-fareast-font-family:"Times New Roman";
			mso-fareast-theme-font:major-fareast;
			mso-hansi-font-family:"Aptos Display";
			mso-hansi-theme-font:major-latin;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:major-bidi;
			color:#0F4761;
			mso-themecolor:accent1;
			mso-themeshade:191;
			mso-font-kerning:1.0pt;
			mso-ligatures:standardcontextual;
			mso-fareast-language:EN-US;
			font-weight:normal;}
		h2
			{mso-style-priority:9;
			mso-style-qformat:yes;
			mso-style-link:"Heading 2 Char";
			mso-style-next:Normal;
			margin-top:8.0pt;
			margin-right:0cm;
			margin-bottom:4.0pt;
			margin-left:0cm;
			line-height:107%;
			mso-pagination:widow-orphan lines-together;
			page-break-after:avoid;
			mso-outline-level:2;
			font-size:16.0pt;
			font-family:"Aptos Display",sans-serif;
			mso-ascii-font-family:"Aptos Display";
			mso-ascii-theme-font:major-latin;
			mso-fareast-font-family:"Times New Roman";
			mso-fareast-theme-font:major-fareast;
			mso-hansi-font-family:"Aptos Display";
			mso-hansi-theme-font:major-latin;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:major-bidi;
			mso-themecolor:accent1;
			mso-themeshade:191;
			mso-font-kerning:1.0pt;
			mso-ligatures:standardcontextual;
			mso-fareast-language:EN-US;
			font-weight:normal;}
		h3
			{mso-style-noshow:yes;
			mso-style-priority:9;
			mso-style-qformat:yes;
			mso-style-link:"Heading 3 Char";
			mso-style-next:Normal;
			margin-top:8.0pt;
			margin-right:0cm;
			margin-bottom:4.0pt;
			margin-left:0cm;
			line-height:107%;
			mso-pagination:widow-orphan lines-together;
			page-break-after:avoid;
			mso-outline-level:3;
			font-size:14.0pt;
			font-family:"Aptos",sans-serif;
			mso-ascii-font-family:Aptos;
			mso-ascii-theme-font:minor-latin;
			mso-fareast-font-family:"Times New Roman";
			mso-fareast-theme-font:major-fareast;
			mso-hansi-font-family:Aptos;
			mso-hansi-theme-font:minor-latin;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:major-bidi;
			color:#0F4761;
			mso-themecolor:accent1;
			mso-themeshade:191;
			mso-font-kerning:1.0pt;
			mso-ligatures:standardcontextual;
			mso-fareast-language:EN-US;
			font-weight:normal;}
		h4
			{mso-style-noshow:yes;
			mso-style-priority:9;
			mso-style-qformat:yes;
			mso-style-link:"Heading 4 Char";
			mso-style-next:Normal;
			margin-top:4.0pt;
			margin-right:0cm;
			margin-bottom:2.0pt;
			margin-left:0cm;
			line-height:107%;
			mso-pagination:widow-orphan lines-together;
			page-break-after:avoid;
			mso-outline-level:4;
			font-size:11.0pt;
			font-family:"Aptos",sans-serif;
			mso-ascii-font-family:Aptos;
			mso-ascii-theme-font:minor-latin;
			mso-fareast-font-family:"Times New Roman";
			mso-fareast-theme-font:major-fareast;
			mso-hansi-font-family:Aptos;
			mso-hansi-theme-font:minor-latin;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:major-bidi;
			color:#0F4761;
			mso-themecolor:accent1;
			mso-themeshade:191;
			mso-font-kerning:1.0pt;
			mso-ligatures:standardcontextual;
			mso-fareast-language:EN-US;
			font-weight:normal;
			font-style:italic;}
		h5
			{mso-style-noshow:yes;
			mso-style-priority:9;
			mso-style-qformat:yes;
			mso-style-link:"Heading 5 Char";
			mso-style-next:Normal;
			margin-top:4.0pt;
			margin-right:0cm;
			margin-bottom:2.0pt;
			margin-left:0cm;
			line-height:107%;
			mso-pagination:widow-orphan lines-together;
			page-break-after:avoid;
			mso-outline-level:5;
			font-size:11.0pt;
			font-family:"Aptos",sans-serif;
			mso-ascii-font-family:Aptos;
			mso-ascii-theme-font:minor-latin;
			mso-fareast-font-family:"Times New Roman";
			mso-fareast-theme-font:major-fareast;
			mso-hansi-font-family:Aptos;
			mso-hansi-theme-font:minor-latin;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:major-bidi;
			color:#0F4761;
			mso-themecolor:accent1;
			mso-themeshade:191;
			mso-font-kerning:1.0pt;
			mso-ligatures:standardcontextual;
			mso-fareast-language:EN-US;
			font-weight:normal;}
		h6
			{mso-style-noshow:yes;
			mso-style-priority:9;
			mso-style-qformat:yes;
			mso-style-link:"Heading 6 Char";
			mso-style-next:Normal;
			margin-top:2.0pt;
			margin-right:0cm;
			margin-bottom:0cm;
			margin-left:0cm;
			line-height:107%;
			mso-pagination:widow-orphan lines-together;
			page-break-after:avoid;
			mso-outline-level:6;
			font-size:11.0pt;
			font-family:"Aptos",sans-serif;
			mso-ascii-font-family:Aptos;
			mso-ascii-theme-font:minor-latin;
			mso-fareast-font-family:"Times New Roman";
			mso-fareast-theme-font:major-fareast;
			mso-hansi-font-family:Aptos;
			mso-hansi-theme-font:minor-latin;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:major-bidi;
			color:#595959;
			mso-themecolor:text1;
			mso-themetint:166;
			mso-font-kerning:1.0pt;
			mso-ligatures:standardcontextual;
			mso-fareast-language:EN-US;
			font-weight:normal;
			font-style:italic;}
		p.MsoHeading7, li.MsoHeading7, div.MsoHeading7
			{mso-style-noshow:yes;
			mso-style-priority:9;
			mso-style-qformat:yes;
			mso-style-link:"Heading 7 Char";
			mso-style-next:Normal;
			margin-top:2.0pt;
			margin-right:0cm;
			margin-bottom:0cm;
			margin-left:0cm;
			line-height:107%;
			mso-pagination:widow-orphan lines-together;
			page-break-after:avoid;
			mso-outline-level:7;
			font-size:11.0pt;
			font-family:"Aptos",sans-serif;
			mso-ascii-font-family:Aptos;
			mso-ascii-theme-font:minor-latin;
			mso-fareast-font-family:"Times New Roman";
			mso-fareast-theme-font:major-fareast;
			mso-hansi-font-family:Aptos;
			mso-hansi-theme-font:minor-latin;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:major-bidi;
			color:#595959;
			mso-themecolor:text1;
			mso-themetint:166;
			mso-font-kerning:1.0pt;
			mso-ligatures:standardcontextual;
			mso-fareast-language:EN-US;}
		p.MsoHeading8, li.MsoHeading8, div.MsoHeading8
			{mso-style-noshow:yes;
			mso-style-priority:9;
			mso-style-qformat:yes;
			mso-style-link:"Heading 8 Char";
			mso-style-next:Normal;
			margin:0cm;
			line-height:107%;
			mso-pagination:widow-orphan lines-together;
			page-break-after:avoid;
			mso-outline-level:8;
			font-size:11.0pt;
			font-family:"Aptos",sans-serif;
			mso-ascii-font-family:Aptos;
			mso-ascii-theme-font:minor-latin;
			mso-fareast-font-family:"Times New Roman";
			mso-fareast-theme-font:major-fareast;
			mso-hansi-font-family:Aptos;
			mso-hansi-theme-font:minor-latin;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:major-bidi;
			color:#272727;
			mso-themecolor:text1;
			mso-themetint:216;
			mso-font-kerning:1.0pt;
			mso-ligatures:standardcontextual;
			mso-fareast-language:EN-US;
			font-style:italic;}
		p.MsoHeading9, li.MsoHeading9, div.MsoHeading9
			{mso-style-noshow:yes;
			mso-style-priority:9;
			mso-style-qformat:yes;
			mso-style-link:"Heading 9 Char";
			mso-style-next:Normal;
			margin:0cm;
			line-height:107%;
			mso-pagination:widow-orphan lines-together;
			page-break-after:avoid;
			mso-outline-level:9;
			font-size:11.0pt;
			font-family:"Aptos",sans-serif;
			mso-ascii-font-family:Aptos;
			mso-ascii-theme-font:minor-latin;
			mso-fareast-font-family:"Times New Roman";
			mso-fareast-theme-font:major-fareast;
			mso-hansi-font-family:Aptos;
			mso-hansi-theme-font:minor-latin;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:major-bidi;
			color:#272727;
			mso-themecolor:text1;
			mso-themetint:216;
			mso-font-kerning:1.0pt;
			mso-ligatures:standardcontextual;
			mso-fareast-language:EN-US;}
		p.MsoTitle, li.MsoTitle, div.MsoTitle
			{mso-style-priority:10;
			mso-style-unhide:no;
			mso-style-qformat:yes;
			mso-style-link:"Title Char";
			mso-style-next:Normal;
			margin-top:0cm;
			margin-right:0cm;
			margin-bottom:4.0pt;
			margin-left:0cm;
			mso-add-space:auto;
			mso-pagination:widow-orphan;
			font-size:28.0pt;
			font-family:"Aptos Display",sans-serif;
			mso-ascii-font-family:"Aptos Display";
			mso-ascii-theme-font:major-latin;
			mso-fareast-font-family:"Times New Roman";
			mso-fareast-theme-font:major-fareast;
			mso-hansi-font-family:"Aptos Display";
			mso-hansi-theme-font:major-latin;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:major-bidi;
			letter-spacing:-.5pt;
			mso-font-kerning:14.0pt;
			mso-ligatures:standardcontextual;
			mso-fareast-language:EN-US;}
		p.MsoTitleCxSpFirst, li.MsoTitleCxSpFirst, div.MsoTitleCxSpFirst
			{mso-style-priority:10;
			mso-style-unhide:no;
			mso-style-qformat:yes;
			mso-style-link:"Title Char";
			mso-style-next:Normal;
			mso-style-type:export-only;
			margin:0cm;
			mso-add-space:auto;
			mso-pagination:widow-orphan;
			font-size:28.0pt;
			font-family:"Aptos Display",sans-serif;
			mso-ascii-font-family:"Aptos Display";
			mso-ascii-theme-font:major-latin;
			mso-fareast-font-family:"Times New Roman";
			mso-fareast-theme-font:major-fareast;
			mso-hansi-font-family:"Aptos Display";
			mso-hansi-theme-font:major-latin;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:major-bidi;
			letter-spacing:-.5pt;
			mso-font-kerning:14.0pt;
			mso-ligatures:standardcontextual;
			mso-fareast-language:EN-US;}
		p.MsoTitleCxSpMiddle, li.MsoTitleCxSpMiddle, div.MsoTitleCxSpMiddle
			{mso-style-priority:10;
			mso-style-unhide:no;
			mso-style-qformat:yes;
			mso-style-link:"Title Char";
			mso-style-next:Normal;
			mso-style-type:export-only;
			margin:0cm;
			mso-add-space:auto;
			mso-pagination:widow-orphan;
			font-size:28.0pt;
			font-family:"Aptos Display",sans-serif;
			mso-ascii-font-family:"Aptos Display";
			mso-ascii-theme-font:major-latin;
			mso-fareast-font-family:"Times New Roman";
			mso-fareast-theme-font:major-fareast;
			mso-hansi-font-family:"Aptos Display";
			mso-hansi-theme-font:major-latin;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:major-bidi;
			letter-spacing:-.5pt;
			mso-font-kerning:14.0pt;
			mso-ligatures:standardcontextual;
			mso-fareast-language:EN-US;}
		p.MsoTitleCxSpLast, li.MsoTitleCxSpLast, div.MsoTitleCxSpLast
			{mso-style-priority:10;
			mso-style-unhide:no;
			mso-style-qformat:yes;
			mso-style-link:"Title Char";
			mso-style-next:Normal;
			mso-style-type:export-only;
			margin-top:0cm;
			margin-right:0cm;
			margin-bottom:4.0pt;
			margin-left:0cm;
			mso-add-space:auto;
			mso-pagination:widow-orphan;
			font-size:28.0pt;
			font-family:"Aptos Display",sans-serif;
			mso-ascii-font-family:"Aptos Display";
			mso-ascii-theme-font:major-latin;
			mso-fareast-font-family:"Times New Roman";
			mso-fareast-theme-font:major-fareast;
			mso-hansi-font-family:"Aptos Display";
			mso-hansi-theme-font:major-latin;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:major-bidi;
			letter-spacing:-.5pt;
			mso-font-kerning:14.0pt;
			mso-ligatures:standardcontextual;
			mso-fareast-language:EN-US;}
		p.MsoSubtitle, li.MsoSubtitle, div.MsoSubtitle
			{mso-style-priority:11;
			mso-style-unhide:no;
			mso-style-qformat:yes;
			mso-style-link:"Subtitle Char";
			mso-style-next:Normal;
			margin-top:0cm;
			margin-right:0cm;
			margin-bottom:8.0pt;
			margin-left:0cm;
			line-height:107%;
			mso-pagination:widow-orphan;
			font-size:14.0pt;
			font-family:"Aptos",sans-serif;
			mso-ascii-font-family:Aptos;
			mso-ascii-theme-font:minor-latin;
			mso-fareast-font-family:"Times New Roman";
			mso-fareast-theme-font:major-fareast;
			mso-hansi-font-family:Aptos;
			mso-hansi-theme-font:minor-latin;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:major-bidi;
			color:#595959;
			mso-themecolor:text1;
			mso-themetint:166;
			letter-spacing:.75pt;
			mso-font-kerning:1.0pt;
			mso-ligatures:standardcontextual;
			mso-fareast-language:EN-US;}
		p
			{mso-style-priority:99;
			mso-margin-top-alt:auto;
			margin-right:0cm;
			mso-margin-bottom-alt:auto;
			margin-left:0cm;
			mso-pagination:widow-orphan;
			font-size:12.0pt;
			font-family:"Times New Roman",serif;
			mso-fareast-font-family:"Times New Roman";}
		p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
			{mso-style-priority:34;
			mso-style-unhide:no;
			mso-style-qformat:yes;
			margin-top:0cm;
			margin-right:0cm;
			margin-bottom:8.0pt;
			margin-left:36.0pt;
			mso-add-space:auto;
			line-height:107%;
			mso-pagination:widow-orphan;
			font-size:11.0pt;
			font-family:"Aptos",sans-serif;
			mso-ascii-font-family:Aptos;
			mso-ascii-theme-font:minor-latin;
			mso-fareast-font-family:Aptos;
			mso-fareast-theme-font:minor-latin;
			mso-hansi-font-family:Aptos;
			mso-hansi-theme-font:minor-latin;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:minor-bidi;
			mso-font-kerning:1.0pt;
			mso-ligatures:standardcontextual;
			mso-fareast-language:EN-US;}
		p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
			{mso-style-priority:34;
			mso-style-unhide:no;
			mso-style-qformat:yes;
			mso-style-type:export-only;
			margin-top:0cm;
			margin-right:0cm;
			margin-bottom:0cm;
			margin-left:36.0pt;
			mso-add-space:auto;
			line-height:107%;
			mso-pagination:widow-orphan;
			font-size:11.0pt;
			font-family:"Aptos",sans-serif;
			mso-ascii-font-family:Aptos;
			mso-ascii-theme-font:minor-latin;
			mso-fareast-font-family:Aptos;
			mso-fareast-theme-font:minor-latin;
			mso-hansi-font-family:Aptos;
			mso-hansi-theme-font:minor-latin;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:minor-bidi;
			mso-font-kerning:1.0pt;
			mso-ligatures:standardcontextual;
			mso-fareast-language:EN-US;}
		p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
			{mso-style-priority:34;
			mso-style-unhide:no;
			mso-style-qformat:yes;
			mso-style-type:export-only;
			margin-top:0cm;
			margin-right:0cm;
			margin-bottom:0cm;
			margin-left:36.0pt;
			mso-add-space:auto;
			line-height:107%;
			mso-pagination:widow-orphan;
			font-size:11.0pt;
			font-family:"Aptos",sans-serif;
			mso-ascii-font-family:Aptos;
			mso-ascii-theme-font:minor-latin;
			mso-fareast-font-family:Aptos;
			mso-fareast-theme-font:minor-latin;
			mso-hansi-font-family:Aptos;
			mso-hansi-theme-font:minor-latin;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:minor-bidi;
			mso-font-kerning:1.0pt;
			mso-ligatures:standardcontextual;
			mso-fareast-language:EN-US;}
		p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
			{mso-style-priority:34;
			mso-style-unhide:no;
			mso-style-qformat:yes;
			mso-style-type:export-only;
			margin-top:0cm;
			margin-right:0cm;
			margin-bottom:8.0pt;
			margin-left:36.0pt;
			mso-add-space:auto;
			line-height:107%;
			mso-pagination:widow-orphan;
			font-size:11.0pt;
			font-family:"Aptos",sans-serif;
			mso-ascii-font-family:Aptos;
			mso-ascii-theme-font:minor-latin;
			mso-fareast-font-family:Aptos;
			mso-fareast-theme-font:minor-latin;
			mso-hansi-font-family:Aptos;
			mso-hansi-theme-font:minor-latin;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:minor-bidi;
			mso-font-kerning:1.0pt;
			mso-ligatures:standardcontextual;
			mso-fareast-language:EN-US;}
		p.MsoQuote, li.MsoQuote, div.MsoQuote
			{mso-style-priority:29;
			mso-style-unhide:no;
			mso-style-qformat:yes;
			mso-style-link:"Quote Char";
			mso-style-next:Normal;
			margin-top:8.0pt;
			margin-right:0cm;
			margin-bottom:8.0pt;
			margin-left:0cm;
			text-align:center;
			line-height:107%;
			mso-pagination:widow-orphan;
			font-size:11.0pt;
			font-family:"Aptos",sans-serif;
			mso-ascii-font-family:Aptos;
			mso-ascii-theme-font:minor-latin;
			mso-fareast-font-family:Aptos;
			mso-fareast-theme-font:minor-latin;
			mso-hansi-font-family:Aptos;
			mso-hansi-theme-font:minor-latin;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:minor-bidi;
			color:#404040;
			mso-themecolor:text1;
			mso-themetint:191;
			mso-font-kerning:1.0pt;
			mso-ligatures:standardcontextual;
			mso-fareast-language:EN-US;
			font-style:italic;}
		p.MsoIntenseQuote, li.MsoIntenseQuote, div.MsoIntenseQuote
			{mso-style-priority:30;
			mso-style-unhide:no;
			mso-style-qformat:yes;
			mso-style-link:"Intense Quote Char";
			mso-style-next:Normal;
			margin-top:18.0pt;
			margin-right:43.2pt;
			margin-bottom:18.0pt;
			margin-left:43.2pt;
			text-align:center;
			line-height:107%;
			mso-pagination:widow-orphan;
			border:none;
			mso-border-top-alt:solid #0F4761 .5pt;
			mso-border-top-themecolor:accent1;
			mso-border-top-themeshade:191;
			mso-border-bottom-alt:solid #0F4761 .5pt;
			mso-border-bottom-themecolor:accent1;
			mso-border-bottom-themeshade:191;
			padding:0cm;
			mso-padding-alt:10.0pt 0cm 10.0pt 0cm;
			font-size:11.0pt;
			font-family:"Aptos",sans-serif;
			mso-ascii-font-family:Aptos;
			mso-ascii-theme-font:minor-latin;
			mso-fareast-font-family:Aptos;
			mso-fareast-theme-font:minor-latin;
			mso-hansi-font-family:Aptos;
			mso-hansi-theme-font:minor-latin;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:minor-bidi;
			color:#0F4761;
			mso-themecolor:accent1;
			mso-themeshade:191;
			mso-font-kerning:1.0pt;
			mso-ligatures:standardcontextual;
			mso-fareast-language:EN-US;
			font-style:italic;}
		span.MsoIntenseEmphasis
			{mso-style-priority:21;
			mso-style-unhide:no;
			mso-style-qformat:yes;
			color:#0F4761;
			mso-themecolor:accent1;
			mso-themeshade:191;
			font-style:italic;}
		span.MsoIntenseReference
			{mso-style-priority:32;
			mso-style-unhide:no;
			mso-style-qformat:yes;
			font-variant:small-caps;
			color:#0F4761;
			mso-themecolor:accent1;
			mso-themeshade:191;
			letter-spacing:.25pt;
			font-weight:bold;}
		span.Heading1Char
			{mso-style-name:"Heading 1 Char";
			mso-style-priority:9;
			mso-style-unhide:no;
			mso-style-locked:yes;
			mso-style-link:"Heading 1";
			mso-ansi-font-size:20.0pt;
			mso-bidi-font-size:20.0pt;
			font-family:"Aptos Display",sans-serif;
			mso-ascii-font-family:"Aptos Display";
			mso-ascii-theme-font:major-latin;
			mso-fareast-font-family:"Times New Roman";
			mso-fareast-theme-font:major-fareast;
			mso-hansi-font-family:"Aptos Display";
			mso-hansi-theme-font:major-latin;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:major-bidi;
			color:#0F4761;
			mso-themecolor:accent1;
			mso-themeshade:191;}
		span.Heading2Char
			{mso-style-name:"Heading 2 Char";
			mso-style-priority:9;
			mso-style-unhide:no;
			mso-style-locked:yes;
			mso-style-link:"Heading 2";
			mso-ansi-font-size:16.0pt;
			mso-bidi-font-size:16.0pt;
			font-family:"Aptos Display",sans-serif;
			mso-ascii-font-family:"Aptos Display";
			mso-ascii-theme-font:major-latin;
			mso-fareast-font-family:"Times New Roman";
			mso-fareast-theme-font:major-fareast;
			mso-hansi-font-family:"Aptos Display";
			mso-hansi-theme-font:major-latin;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:major-bidi;
			color:#0F4761;
			mso-themecolor:accent1;
			mso-themeshade:191;}
		span.Heading3Char
			{mso-style-name:"Heading 3 Char";
			mso-style-noshow:yes;
			mso-style-priority:9;
			mso-style-unhide:no;
			mso-style-locked:yes;
			mso-style-link:"Heading 3";
			mso-ansi-font-size:14.0pt;
			mso-bidi-font-size:14.0pt;
			font-family:"Times New Roman",serif;
			mso-fareast-font-family:"Times New Roman";
			mso-fareast-theme-font:major-fareast;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:major-bidi;
			color:#0F4761;
			mso-themecolor:accent1;
			mso-themeshade:191;}
		span.Heading4Char
			{mso-style-name:"Heading 4 Char";
			mso-style-noshow:yes;
			mso-style-priority:9;
			mso-style-unhide:no;
			mso-style-locked:yes;
			mso-style-link:"Heading 4";
			font-family:"Times New Roman",serif;
			mso-fareast-font-family:"Times New Roman";
			mso-fareast-theme-font:major-fareast;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:major-bidi;
			color:#0F4761;
			mso-themecolor:accent1;
			mso-themeshade:191;
			font-style:italic;}
		span.Heading5Char
			{mso-style-name:"Heading 5 Char";
			mso-style-noshow:yes;
			mso-style-priority:9;
			mso-style-unhide:no;
			mso-style-locked:yes;
			mso-style-link:"Heading 5";
			font-family:"Times New Roman",serif;
			mso-fareast-font-family:"Times New Roman";
			mso-fareast-theme-font:major-fareast;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:major-bidi;
			color:#0F4761;
			mso-themecolor:accent1;
			mso-themeshade:191;}
		span.Heading6Char
			{mso-style-name:"Heading 6 Char";
			mso-style-noshow:yes;
			mso-style-priority:9;
			mso-style-unhide:no;
			mso-style-locked:yes;
			mso-style-link:"Heading 6";
			font-family:"Times New Roman",serif;
			mso-fareast-font-family:"Times New Roman";
			mso-fareast-theme-font:major-fareast;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:major-bidi;
			color:#595959;
			mso-themecolor:text1;
			mso-themetint:166;
			font-style:italic;}
		span.Heading7Char
			{mso-style-name:"Heading 7 Char";
			mso-style-noshow:yes;
			mso-style-priority:9;
			mso-style-unhide:no;
			mso-style-locked:yes;
			mso-style-link:"Heading 7";
			font-family:"Times New Roman",serif;
			mso-fareast-font-family:"Times New Roman";
			mso-fareast-theme-font:major-fareast;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:major-bidi;
			color:#595959;
			mso-themecolor:text1;
			mso-themetint:166;}
		span.Heading8Char
			{mso-style-name:"Heading 8 Char";
			mso-style-noshow:yes;
			mso-style-priority:9;
			mso-style-unhide:no;
			mso-style-locked:yes;
			mso-style-link:"Heading 8";
			font-family:"Times New Roman",serif;
			mso-fareast-font-family:"Times New Roman";
			mso-fareast-theme-font:major-fareast;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:major-bidi;
			color:#272727;
			mso-themecolor:text1;
			mso-themetint:216;
			font-style:italic;}
		span.Heading9Char
			{mso-style-name:"Heading 9 Char";
			mso-style-noshow:yes;
			mso-style-priority:9;
			mso-style-unhide:no;
			mso-style-locked:yes;
			mso-style-link:"Heading 9";
			font-family:"Times New Roman",serif;
			mso-fareast-font-family:"Times New Roman";
			mso-fareast-theme-font:major-fareast;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:major-bidi;
			color:#272727;
			mso-themecolor:text1;
			mso-themetint:216;}
		span.TitleChar
			{mso-style-name:"Title Char";
			mso-style-priority:10;
			mso-style-unhide:no;
			mso-style-locked:yes;
			mso-style-link:Title;
			mso-ansi-font-size:28.0pt;
			mso-bidi-font-size:28.0pt;
			font-family:"Aptos Display",sans-serif;
			mso-ascii-font-family:"Aptos Display";
			mso-ascii-theme-font:major-latin;
			mso-fareast-font-family:"Times New Roman";
			mso-fareast-theme-font:major-fareast;
			mso-hansi-font-family:"Aptos Display";
			mso-hansi-theme-font:major-latin;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:major-bidi;
			letter-spacing:-.5pt;
			mso-font-kerning:14.0pt;}
		span.SubtitleChar
			{mso-style-name:"Subtitle Char";
			mso-style-priority:11;
			mso-style-unhide:no;
			mso-style-locked:yes;
			mso-style-link:Subtitle;
			mso-ansi-font-size:14.0pt;
			mso-bidi-font-size:14.0pt;
			font-family:"Times New Roman",serif;
			mso-fareast-font-family:"Times New Roman";
			mso-fareast-theme-font:major-fareast;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:major-bidi;
			color:#595959;
			mso-themecolor:text1;
			mso-themetint:166;
			letter-spacing:.75pt;}
		span.QuoteChar
			{mso-style-name:"Quote Char";
			mso-style-priority:29;
			mso-style-unhide:no;
			mso-style-locked:yes;
			mso-style-link:Quote;
			color:#404040;
			mso-themecolor:text1;
			mso-themetint:191;
			font-style:italic;}
		span.IntenseQuoteChar
			{mso-style-name:"Intense Quote Char";
			mso-style-priority:30;
			mso-style-unhide:no;
			mso-style-locked:yes;
			mso-style-link:"Intense Quote";
			color:#0F4761;
			mso-themecolor:accent1;
			mso-themeshade:191;
			font-style:italic;}
		span.SpellE
			{mso-style-name:"";
			mso-spl-e:yes;}
		span.GramE
			{mso-style-name:"";
			mso-gram-e:yes;}
		.MsoChpDefault
			{mso-style-type:export-only;
			mso-default-props:yes;
			mso-ascii-font-family:Aptos;
			mso-ascii-theme-font:minor-latin;
			mso-fareast-font-family:Aptos;
			mso-fareast-theme-font:minor-latin;
			mso-hansi-font-family:Aptos;
			mso-hansi-theme-font:minor-latin;
			mso-bidi-font-family:"Times New Roman";
			mso-bidi-theme-font:minor-bidi;
			mso-fareast-language:EN-US;}
		.MsoPapDefault
			{mso-style-type:export-only;
			margin-bottom:8.0pt;
			line-height:107%;}
		@page WordSection1
			{size:595.3pt 841.9pt;
			margin:72.0pt 72.0pt 72.0pt 72.0pt;
			mso-header-margin:35.4pt;
			mso-footer-margin:35.4pt;
			mso-paper-source:0;}
		div.WordSection1
			{page:WordSection1;background-color: white;}
		 /* List Definitions */
		 @list l0
			{mso-list-id:42103237;
			mso-list-template-ids:-1349229882;}
		@list l0:level1
			{mso-level-start-at:41;
			mso-level-tab-stop:36.0pt;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l1
			{mso-list-id:68431180;
			mso-list-type:hybrid;
			mso-list-template-ids:-156989264 201916417 201916419 201916421 201916417 201916419 201916421 201916417 201916419 201916421;}
		@list l1:level1
			{mso-level-number-format:bullet;
			mso-level-text:\F0B7;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;
			font-family:Symbol;}
		@list l1:level2
			{mso-level-number-format:bullet;
			mso-level-text:o;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;
			font-family:"Courier New";}
		@list l1:level3
			{mso-level-number-format:bullet;
			mso-level-text:\F0A7;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;
			font-family:Wingdings;}
		@list l1:level4
			{mso-level-number-format:bullet;
			mso-level-text:\F0B7;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;
			font-family:Symbol;}
		@list l1:level5
			{mso-level-number-format:bullet;
			mso-level-text:o;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;
			font-family:"Courier New";}
		@list l1:level6
			{mso-level-number-format:bullet;
			mso-level-text:\F0A7;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;
			font-family:Wingdings;}
		@list l1:level7
			{mso-level-number-format:bullet;
			mso-level-text:\F0B7;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;
			font-family:Symbol;}
		@list l1:level8
			{mso-level-number-format:bullet;
			mso-level-text:o;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;
			font-family:"Courier New";}
		@list l1:level9
			{mso-level-number-format:bullet;
			mso-level-text:\F0A7;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;
			font-family:Wingdings;}
		@list l2
			{mso-list-id:323973352;
			mso-list-template-ids:1698975490;}
		@list l2:level1
			{mso-level-start-at:39;
			mso-level-tab-stop:36.0pt;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l3
			{mso-list-id:358119413;
			mso-list-template-ids:-1538732836;}
		@list l3:level1
			{mso-level-start-at:12;
			mso-level-tab-stop:36.0pt;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l4
			{mso-list-id:469321276;
			mso-list-template-ids:1585878458;}
		@list l4:level1
			{mso-level-start-at:10;
			mso-level-tab-stop:36.0pt;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l5
			{mso-list-id:499319888;
			mso-list-template-ids:465622890;}
		@list l5:level1
			{mso-level-start-at:21;
			mso-level-tab-stop:36.0pt;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l6
			{mso-list-id:643773610;
			mso-list-template-ids:680416908;}
		@list l6:level1
			{mso-level-start-at:5;
			mso-level-tab-stop:36.0pt;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l7
			{mso-list-id:649288900;
			mso-list-type:hybrid;
			mso-list-template-ids:-764665104 201916431 201916441 201916443 201916431 201916441 201916443 201916431 201916441 201916443;}
		@list l7:level1
			{mso-level-start-at:4;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l7:level2
			{mso-level-number-format:alpha-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l7:level3
			{mso-level-number-format:roman-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			text-indent:-9.0pt;}
		@list l7:level4
			{mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l7:level5
			{mso-level-number-format:alpha-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l7:level6
			{mso-level-number-format:roman-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			text-indent:-9.0pt;}
		@list l7:level7
			{mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l7:level8
			{mso-level-number-format:alpha-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l7:level9
			{mso-level-number-format:roman-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			text-indent:-9.0pt;}
		@list l8
			{mso-list-id:768427922;
			mso-list-template-ids:-588068432;}
		@list l8:level1
			{mso-level-start-at:43;
			mso-level-tab-stop:36.0pt;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l9
			{mso-list-id:773405138;
			mso-list-template-ids:-261746684;}
		@list l9:level1
			{mso-level-start-at:15;
			mso-level-tab-stop:36.0pt;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l10
			{mso-list-id:814682974;
			mso-list-type:hybrid;
			mso-list-template-ids:1894311556 201916435 -1 -1 -1 -1 -1 -1 -1 -1;}
		@list l10:level1
			{mso-level-number-format:roman-upper;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			text-indent:-18.0pt;}
		@list l10:level2
			{mso-level-number-format:alpha-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l10:level3
			{mso-level-number-format:roman-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			text-indent:-9.0pt;}
		@list l10:level4
			{mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l10:level5
			{mso-level-number-format:alpha-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l10:level6
			{mso-level-number-format:roman-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			text-indent:-9.0pt;}
		@list l10:level7
			{mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l10:level8
			{mso-level-number-format:alpha-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l10:level9
			{mso-level-number-format:roman-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			text-indent:-9.0pt;}
		@list l11
			{mso-list-id:956256005;
			mso-list-type:hybrid;
			mso-list-template-ids:1857463492 201916431 201916441 201916443 201916431 201916441 201916443 201916431 201916441 201916443;}
		@list l11:level1
			{mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l11:level2
			{mso-level-number-format:alpha-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l11:level3
			{mso-level-number-format:roman-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			text-indent:-9.0pt;}
		@list l11:level4
			{mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l11:level5
			{mso-level-number-format:alpha-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l11:level6
			{mso-level-number-format:roman-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			text-indent:-9.0pt;}
		@list l11:level7
			{mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l11:level8
			{mso-level-number-format:alpha-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l11:level9
			{mso-level-number-format:roman-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			text-indent:-9.0pt;}
		@list l12
			{mso-list-id:1041320430;
			mso-list-template-ids:-510749924;}
		@list l12:level1
			{mso-level-start-at:53;
			mso-level-tab-stop:36.0pt;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l13
			{mso-list-id:1234581952;
			mso-list-type:hybrid;
			mso-list-template-ids:-1755029600 201916431 201916441 201916443 201916431 201916441 201916443 201916431 201916441 201916443;}
		@list l13:level1
			{mso-level-start-at:4;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			margin-left:18.0pt;
			text-indent:-18.0pt;}
		@list l13:level2
			{mso-level-number-format:alpha-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			margin-left:54.0pt;
			text-indent:-18.0pt;}
		@list l13:level3
			{mso-level-number-format:roman-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			margin-left:90.0pt;
			text-indent:-9.0pt;}
		@list l13:level4
			{mso-level-tab-stop:none;
			mso-level-number-position:left;
			margin-left:126.0pt;
			text-indent:-18.0pt;}
		@list l13:level5
			{mso-level-number-format:alpha-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			margin-left:162.0pt;
			text-indent:-18.0pt;}
		@list l13:level6
			{mso-level-number-format:roman-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			margin-left:198.0pt;
			text-indent:-9.0pt;}
		@list l13:level7
			{mso-level-tab-stop:none;
			mso-level-number-position:left;
			margin-left:234.0pt;
			text-indent:-18.0pt;}
		@list l13:level8
			{mso-level-number-format:alpha-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			margin-left:270.0pt;
			text-indent:-18.0pt;}
		@list l13:level9
			{mso-level-number-format:roman-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			margin-left:306.0pt;
			text-indent:-9.0pt;}
		@list l14
			{mso-list-id:1243567234;
			mso-list-template-ids:-1335436422;}
		@list l14:level1
			{mso-level-start-at:5;
			mso-level-tab-stop:36.0pt;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l15
			{mso-list-id:1250190328;
			mso-list-template-ids:-821644114;}
		@list l15:level1
			{mso-level-start-at:23;
			mso-level-tab-stop:36.0pt;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l16
			{mso-list-id:1315795710;
			mso-list-type:hybrid;
			mso-list-template-ids:1047807048 201916431 201916441 201916443 201916431 201916441 201916443 201916431 201916441 201916443;}
		@list l16:level1
			{mso-level-start-at:4;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			margin-left:18.0pt;
			text-indent:-18.0pt;}
		@list l16:level2
			{mso-level-number-format:alpha-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			margin-left:54.0pt;
			text-indent:-18.0pt;}
		@list l16:level3
			{mso-level-number-format:roman-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			margin-left:90.0pt;
			text-indent:-9.0pt;}
		@list l16:level4
			{mso-level-tab-stop:none;
			mso-level-number-position:left;
			margin-left:126.0pt;
			text-indent:-18.0pt;}
		@list l16:level5
			{mso-level-number-format:alpha-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			margin-left:162.0pt;
			text-indent:-18.0pt;}
		@list l16:level6
			{mso-level-number-format:roman-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			margin-left:198.0pt;
			text-indent:-9.0pt;}
		@list l16:level7
			{mso-level-tab-stop:none;
			mso-level-number-position:left;
			margin-left:234.0pt;
			text-indent:-18.0pt;}
		@list l16:level8
			{mso-level-number-format:alpha-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			margin-left:270.0pt;
			text-indent:-18.0pt;}
		@list l16:level9
			{mso-level-number-format:roman-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			margin-left:306.0pt;
			text-indent:-9.0pt;}
		@list l17
			{mso-list-id:1451129333;
			mso-list-template-ids:-277702300;}
		@list l17:level1
			{mso-level-start-at:19;
			mso-level-tab-stop:36.0pt;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l18
			{mso-list-id:1540125329;
			mso-list-type:hybrid;
			mso-list-template-ids:63616710 201916435 201916441 201916443 201916431 201916441 201916443 201916431 201916441 201916443;}
		@list l18:level1
			{mso-level-number-format:roman-upper;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			text-indent:-18.0pt;}
		@list l18:level2
			{mso-level-number-format:alpha-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l18:level3
			{mso-level-number-format:roman-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			text-indent:-9.0pt;}
		@list l18:level4
			{mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l18:level5
			{mso-level-number-format:alpha-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l18:level6
			{mso-level-number-format:roman-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			text-indent:-9.0pt;}
		@list l18:level7
			{mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l18:level8
			{mso-level-number-format:alpha-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l18:level9
			{mso-level-number-format:roman-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			text-indent:-9.0pt;}
		@list l19
			{mso-list-id:1551304073;
			mso-list-template-ids:73714486;}
		@list l19:level1
			{mso-level-start-at:52;
			mso-level-tab-stop:36.0pt;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l20
			{mso-list-id:1578897949;
			mso-list-template-ids:1136691030;}
		@list l20:level1
			{mso-level-start-at:51;
			mso-level-tab-stop:36.0pt;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l21
			{mso-list-id:1659649561;
			mso-list-type:hybrid;
			mso-list-template-ids:-198695896 201916435 201916441 201916443 201916431 201916441 201916443 201916431 201916441 201916443;}
		@list l21:level1
			{mso-level-number-format:roman-upper;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			text-indent:-18.0pt;}
		@list l21:level2
			{mso-level-number-format:alpha-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l21:level3
			{mso-level-number-format:roman-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			text-indent:-9.0pt;}
		@list l21:level4
			{mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l21:level5
			{mso-level-number-format:alpha-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l21:level6
			{mso-level-number-format:roman-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			text-indent:-9.0pt;}
		@list l21:level7
			{mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l21:level8
			{mso-level-number-format:alpha-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l21:level9
			{mso-level-number-format:roman-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			text-indent:-9.0pt;}
		@list l22
			{mso-list-id:1819876411;
			mso-list-template-ids:1366951534;}
		@list l22:level1
			{mso-level-start-at:55;
			mso-level-tab-stop:36.0pt;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l23
			{mso-list-id:1859856847;
			mso-list-template-ids:1778145298;}
		@list l23:level1
			{mso-level-start-at:14;
			mso-level-tab-stop:36.0pt;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		@list l24
			{mso-list-id:1874221500;
			mso-list-type:hybrid;
			mso-list-template-ids:-1339753882 201916435 -1 -1 -1 -1 -1 -1 -1 -1;}
		@list l24:level1
			{mso-level-number-format:roman-upper;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			text-indent:-18.0pt;}
		@list l24:level2
			{mso-level-number-format:bullet;
			mso-level-text:o;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;
			font-family:"Courier New";}
		@list l24:level3
			{mso-level-number-format:bullet;
			mso-level-text:\F0A7;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;
			font-family:Wingdings;}
		@list l24:level4
			{mso-level-number-format:bullet;
			mso-level-text:\F0B7;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;
			font-family:Symbol;}
		@list l24:level5
			{mso-level-number-format:bullet;
			mso-level-text:o;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;
			font-family:"Courier New";}
		@list l24:level6
			{mso-level-number-format:bullet;
			mso-level-text:\F0A7;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;
			font-family:Wingdings;}
		@list l24:level7
			{mso-level-number-format:bullet;
			mso-level-text:\F0B7;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;
			font-family:Symbol;}
		@list l24:level8
			{mso-level-number-format:bullet;
			mso-level-text:o;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;
			font-family:"Courier New";}
		@list l24:level9
			{mso-level-number-format:bullet;
			mso-level-text:\F0A7;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			text-indent:-18.0pt;
			font-family:Wingdings;}
		@list l25
			{mso-list-id:1972900294;
			mso-list-type:hybrid;
			mso-list-template-ids:-428179878 201916435 201916441 201916443 201916431 201916441 201916443 201916431 201916441 201916443;}
		@list l25:level1
			{mso-level-number-format:roman-upper;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			margin-left:75.0pt;
			text-indent:-18.0pt;}
		@list l25:level2
			{mso-level-number-format:alpha-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			margin-left:111.0pt;
			text-indent:-18.0pt;}
		@list l25:level3
			{mso-level-number-format:roman-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			margin-left:147.0pt;
			text-indent:-9.0pt;}
		@list l25:level4
			{mso-level-tab-stop:none;
			mso-level-number-position:left;
			margin-left:183.0pt;
			text-indent:-18.0pt;}
		@list l25:level5
			{mso-level-number-format:alpha-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			margin-left:219.0pt;
			text-indent:-18.0pt;}
		@list l25:level6
			{mso-level-number-format:roman-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			margin-left:255.0pt;
			text-indent:-9.0pt;}
		@list l25:level7
			{mso-level-tab-stop:none;
			mso-level-number-position:left;
			margin-left:291.0pt;
			text-indent:-18.0pt;}
		@list l25:level8
			{mso-level-number-format:alpha-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:left;
			margin-left:327.0pt;
			text-indent:-18.0pt;}
		@list l25:level9
			{mso-level-number-format:roman-lower;
			mso-level-tab-stop:none;
			mso-level-number-position:right;
			margin-left:363.0pt;
			text-indent:-9.0pt;}
		@list l26
			{mso-list-id:1991667081;
			mso-list-template-ids:-347319160;}
		@list l26:level1
			{mso-level-start-at:22;
			mso-level-tab-stop:32.2pt;
			mso-level-number-position:left;
			margin-left:32.2pt;
			text-indent:-18.0pt;}
		@list l26:level2
			{mso-level-tab-stop:68.2pt;
			mso-level-number-position:left;
			margin-left:68.2pt;
			text-indent:-18.0pt;}
		@list l26:level3
			{mso-level-tab-stop:104.2pt;
			mso-level-number-position:left;
			margin-left:104.2pt;
			text-indent:-18.0pt;}
		@list l26:level4
			{mso-level-tab-stop:140.2pt;
			mso-level-number-position:left;
			margin-left:140.2pt;
			text-indent:-18.0pt;}
		@list l26:level5
			{mso-level-tab-stop:176.2pt;
			mso-level-number-position:left;
			margin-left:176.2pt;
			text-indent:-18.0pt;}
		@list l26:level6
			{mso-level-tab-stop:212.2pt;
			mso-level-number-position:left;
			margin-left:212.2pt;
			text-indent:-18.0pt;}
		@list l26:level7
			{mso-level-tab-stop:248.2pt;
			mso-level-number-position:left;
			margin-left:248.2pt;
			text-indent:-18.0pt;}
		@list l26:level8
			{mso-level-tab-stop:284.2pt;
			mso-level-number-position:left;
			margin-left:284.2pt;
			text-indent:-18.0pt;}
		@list l26:level9
			{mso-level-tab-stop:320.2pt;
			mso-level-number-position:left;
			margin-left:320.2pt;
			text-indent:-18.0pt;}
		@list l27
			{mso-list-id:2009166297;
			mso-list-template-ids:-790200934;}
		@list l27:level1
			{mso-level-start-at:8;
			mso-level-tab-stop:36.0pt;
			mso-level-number-position:left;
			text-indent:-18.0pt;}
		ol
			{margin-bottom:0cm;}
		ul
			{margin-bottom:0cm;}
		-->
	</style>
	<!--[if gte mso 10]>
		<style>
			/* Style Definitions */
			table.MsoNormalTable {
				mso-style-name: "Table Normal";
				mso-tstyle-rowband-size: 0;
				mso-tstyle-colband-size: 0;
				mso-style-noshow: yes;
				mso-style-priority: 99;
				mso-style-parent: "";
				mso-padding-alt: 0cm 5.4pt 0cm 5.4pt;
				mso-para-margin-top: 0cm;
				mso-para-margin-right: 0cm;
				mso-para-margin-bottom: 8pt;
				mso-para-margin-left: 0cm;
				line-height: 107%;
				mso-pagination: widow-orphan;
				font-size: 11pt;
				font-family: "Aptos", sans-serif;
				mso-ascii-font-family: Aptos;
				mso-ascii-theme-font: minor-latin;
				mso-hansi-font-family: Aptos;
				mso-hansi-theme-font: minor-latin;
				mso-font-kerning: 1pt;
				mso-ligatures: standardcontextual;
				mso-fareast-language: EN-US;
			}
		</style>
	<![endif]-->
	<!--[if gte mso 9
		]><xml>
			<o:shapedefaults v:ext="edit" spidmax="1026" /> </xml
	><![endif]-->
	<!--[if gte mso 9
		]><xml>
			<o:shapelayout v:ext="edit">
				<o:idmap v:ext="edit" data="1" /> </o:shapelayout></xml
	><![endif]-->
</head>

<body lang="EN-AU" style="tab-interval: 36pt; word-wrap: break-word">
	<div class="WordSection1">
		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				mso-outline-level: 2;
				background: white;
			"
		>
			<b
				><span
					style="
						font-size: 18pt;
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						color: #323232;
						mso-font-kerning: 0pt;
						mso-ligatures: none;
						mso-fareast-language: EN-AU;
					"
					>Terms and Conditions<o:p></o:p></span
			></b>
		</p>

		<p style="margin-top: 0cm; background: white">
			<strong
				><span
					style="
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						mso-fareast-theme-font: major-fareast;
						color: #212529;
					"
					>Interpretation</span
				></strong
			><span style="font-family: Poppins; color: #212529"><o:p></o:p></span>
		</p>

		<p style="margin-top: 0cm; background: white">
			<span style="font-family: Poppins; color: #212529"
				>In these Terms:<o:p></o:p
			></span>
		</p>

		<p style="margin-top: 0cm; background: white">
			<span style="font-family: Poppins; color: #212529">'</span
			><strong
				><span
					style="
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						mso-fareast-theme-font: major-fareast;
						color: #212529;
					"
					>Agreement</span
				></strong
			><span style="font-family: Poppins; color: #212529"
				>' means an agreement between the Company and Customer for the
				provision of Services. An Agreement will come into existence on the
				date the Customer agrees to these terms via the online quoting
				process.<o:p></o:p
			></span>
		</p>

		<p style="margin-top: 0cm; background: white">
			<span style="font-family: Poppins; color: #212529">'</span
			><strong
				><span
					style="
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						mso-fareast-theme-font: major-fareast;
						color: #212529;
					"
					>Quotation</span
				></strong
			><span style="font-family: Poppins; color: #212529"
				>' means a quotation as emailed to the customer to carry out
				electrical work provided by the Company to the Customer in response to
				a Request for Services which sets out the Quotation Price and the
				details of the Services to which these Terms are annexed.<o:p></o:p
			></span>
		</p>

		<p style="margin-top: 0cm; background: white">
			<span style="font-family: Poppins; color: #212529">'</span
			><strong
				><span
					style="
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						mso-fareast-theme-font: major-fareast;
						color: #212529;
					"
					>Company</span
				></strong
			><span style="font-family: Poppins; color: #212529"
				>' means EVGEN PTY LTD ACN 675 379 441<o:p></o:p
			></span>
		</p>

		<p style="margin-top: 0cm; background: white">
			<span style="font-family: Poppins; color: #212529">'</span
			><strong
				><span
					style="
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						mso-fareast-theme-font: major-fareast;
						color: #212529;
					"
					>Customer</span
				></strong
			><span style="font-family: Poppins; color: #212529"
				>' means the Customer of Services to be supplied by the Company as
				detailed in the quotation.<o:p></o:p
			></span>
		</p>

		<p style="margin-top: 0cm; background: white">
			<span style="font-family: Poppins; color: #212529">'</span
			><strong
				><span
					style="
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						mso-fareast-theme-font: major-fareast;
						color: #212529;
					"
					>Deposit</span
				></strong
			><span style="font-family: Poppins; color: #212529"
				>' means the deposit amount set out in the quotation.<o:p></o:p
			></span>
		</p>

		<p style="margin-top: 0cm; background: white">
			<span style="font-family: Poppins; color: #212529">'</span
			><strong
				><span
					style="
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						mso-fareast-theme-font: major-fareast;
						color: #212529;
					"
					>Goods</span
				></strong
			><span style="font-family: Poppins; color: #212529"
				>' means the goods required to be purchased and supplied by the
				Customer to complete the <span class="GramE">Services;</span
				><o:p></o:p
			></span>
		</p>

		<p style="margin-top: 0cm; background: white">
			<span style="font-family: Poppins; color: #212529">'</span
			><strong
				><span
					style="
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						mso-fareast-theme-font: major-fareast;
						color: #212529;
					"
					>Payment Dates</span
				></strong
			><span style="font-family: Poppins; color: #212529"
				>' means the payment dates as specified on the quote or as otherwise
				agreed in writing by the parties.<o:p></o:p
			></span>
		</p>

		<p style="margin-top: 0cm; background: white">
			<span style="font-family: Poppins; color: #212529">'</span
			><strong
				><span
					style="
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						mso-fareast-theme-font: major-fareast;
						color: #212529;
					"
					>Price</span
				></strong
			><span style="font-family: Poppins; color: #212529"
				>' means the Quotation Price as adjusted by the Company in accordance
				with these Terms as necessary.<o:p></o:p
			></span>
		</p>

		<p style="margin-top: 0cm; background: white">
			<span style="font-family: Poppins; color: #212529">'</span
			><strong
				><span
					style="
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						mso-fareast-theme-font: major-fareast;
						color: #212529;
					"
					>Quotation Price</span
				></strong
			><span style="font-family: Poppins; color: #212529"
				>' means the total amount payable for the Services as detailed in the
				quote or as provided for in accordance with the rates set out in the
				quote, including applicable GST, and any cost of delivery to the
				<span class="GramE">Customer;</span><o:p></o:p
			></span>
		</p>

		<p style="margin-top: 0cm; background: white">
			<span style="font-family: Poppins; color: #212529">'</span
			><strong
				><span
					style="
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						mso-fareast-theme-font: major-fareast;
						color: #212529;
					"
					>Services</span
				></strong
			><span style="font-family: Poppins; color: #212529"
				>' means the services and Goods to be supplied by the Company to the
				Customer as detailed in the Quotation and includes any parts thereof;
				and<o:p></o:p
			></span>
		</p>

		<p style="margin-top: 0cm; background: white">
			<span style="font-family: Poppins; color: #212529">'</span
			><strong
				><span
					style="
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						mso-fareast-theme-font: major-fareast;
						color: #212529;
					"
					>Website</span
				></strong
			><span style="font-family: Poppins; color: #212529"
				>' means the Company's website.<o:p></o:p
			></span>
		</p>

		<p style="margin-top: 0cm; background: white">
			<strong
				><span
					style="
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						mso-fareast-theme-font: major-fareast;
						color: #212529;
					"
					><br />
					General</span
				></strong
			><span style="font-family: Poppins; color: #212529"><o:p></o:p></span>
		</p>

		<p style="margin-top: 0cm; background: white">
			<span style="font-family: Poppins; color: #212529"
				>1. These Terms will apply to the supply of the Services despite any
				conflicting terms proposed by the Customer, unless waived in writing
				by the Company.<o:p></o:p
			></span>
		</p>

		<p style="margin-top: 0cm; background: white">
			<span style="font-family: Poppins; color: #212529"
				>2. These Terms together with the Quotation constitute the entire
				Agreement.<o:p></o:p
			></span>
		</p>

		<p style="margin-top: 0cm; background: white">
			<span style="font-family: Poppins; color: #212529"
				>3. The Customer must provide the Company with all necessary
				information in relation to the Services within a sufficient time to
				enable the Company to perform its obligations under these Terms.<o:p
				></o:p
			></span>
		</p>

		<p style="margin-top: 0cm; background: white">
			<strong
				><span
					style="
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						mso-fareast-theme-font: major-fareast;
						color: #212529;
					"
					>Formation of Agreement</span
				></strong
			><span style="font-family: Poppins; color: #212529"><o:p></o:p></span>
		</p>

		<p style="margin-top: 0cm; background: white">
			<span style="font-family: Poppins; color: #212529"
				>An Agreement will be formed between the Customer and Company when a
				quotation has been sent to the customer and the customer has accepted
				the standard terms and conditions during the quotation process.<o:p
				></o:p
			></span>
		</p>

		<p style="margin-top: 0cm; background: white">
			<span style="font-family: Poppins; color: #212529"
				>An Agreement will be binding on the Customer and Company and will
				come into existence on the date payment terms are agreed or on the
				date payment has been received by the company on behalf of the
				customer, or on the date a deposit for services has been received by
				the company on behalf of the customer, as dictated in the emailed
				quotation.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<b
				><span
					style="
						font-size: 12pt;
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						color: #212529;
						mso-font-kerning: 0pt;
						mso-ligatures: none;
						mso-fareast-language: EN-AU;
					"
					>Quotation</span
				></b
			><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>The Customer must provide sufficient information when submitting a
				Quotation to enable the Company to provide an accurate Quotation
				Price.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>Any deficiency in the Services caused by inadequate or inaccurate
				information provided in a Quotation will be the Customer's
				responsibility.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>The Company will not be liable for any loss or damage caused
				<span class="GramE">as a result of</span> the Customer's inadequate or
				inaccurate information, subject to the Company's negligence or breach
				of these Terms.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<b
				><span
					style="
						font-size: 12pt;
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						color: #212529;
						mso-font-kerning: 0pt;
						mso-ligatures: none;
						mso-fareast-language: EN-AU;
					"
					>Changes to Quotations</span
				></b
			><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>In the event that the Customer requests a variation to a quotation or
				any of its details, the Company will provide the Customer
				<span class="GramE">an</span> variation email detailing the amended
				Services, the amended Quotation Price, and the likely delay, if any,
				in providing the Services ('<b>Variation Terms</b>').<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>The Customer must provide its written or electronic acceptance of the
				Variation Terms before the Company will provide the amended
				Services.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<b
				><span
					style="
						font-size: 12pt;
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						color: #212529;
						mso-font-kerning: 0pt;
						mso-ligatures: none;
						mso-fareast-language: EN-AU;
					"
					>Price</span
				></b
			><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>The quotation emailed is automatically revoked 30 days after it is
				issued if not accepted.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>The Company reserves the right at any time before the delivery of the
				Services, by giving prior written notice to the Customer, to increase
				the Quotation Price to reflect any increase in the cost to the Company
				due to any factor beyond its control, (including without limitation
				changes in taxation laws, significant increase in the costs of labour,
				materials, or other costs of manufacture), any change in delivery
				dates, quantities or specifications for the Services requested by the
				Customer, or any delay caused by the Customer's failure to give the
				Company adequate information or instructions. If the Customer does not
				accept the change in Quotation Price, the Customer may terminate these
				Terms immediately by providing written notice to the Company.<o:p
				></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<b
				><span
					style="
						font-size: 12pt;
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						color: #212529;
						mso-font-kerning: 0pt;
						mso-ligatures: none;
						mso-fareast-language: EN-AU;
					"
					>Completion</span
				></b
			><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>The Company will not be liable for loss or damage (including
				consequential damage) arising out of the lateness of completion or
				non-completion of the Services.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<b
				><span
					style="
						font-size: 12pt;
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						color: #212529;
						mso-font-kerning: 0pt;
						mso-ligatures: none;
						mso-fareast-language: EN-AU;
					"
					>Payments</span
				></b
			><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>Unless otherwise agreed by the Company in writing, the Customer must
				pay:<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>1. the Deposit to the Company upon accepting the quotation; and<o:p
				></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>2. the total Price without deduction in accordance with these Terms
				and the payment terms set out in the quotation.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>3. In the event of non-payment of the Price in accordance with these
				Terms or the Quotation, the Customer will pay all reasonable
				collection expenses, legal costs and any other reasonable expenses
				incurred by the Company in connection with the non-payment.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>4. In If the Customer fails to pay the Price to the Company in
				accordance with these Terms, the Company will issue a default notice
				in relation to any amount due and not paid and give the Customer not
				less than 14 days' notice within which to remedy the non-payment. If
				the non-payment is not remedied within the period stipulated within
				such notice, then the Company may in its absolute discretion acting
				<span class="GramE">reasonably;</span><o:p></o:p
			></span>
		</p>

		<p
			class="MsoListParagraphCxSpFirst"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				margin-left: 75pt;
				mso-add-space: auto;
				text-indent: -75pt;
				mso-text-indent-alt: -18pt;
				line-height: normal;
				mso-list: l25 level1 lfo11;
				background: white;
			"
		>
			<![if !supportLists]><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: Poppins;
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><span style="mso-list: Ignore"
					><span style="font: 7pt 'Times New Roman'"
						>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
					>I.<span style="font: 7pt 'Times New Roman'"
						>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span
				></span
			><![endif]><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>charge the Customer monthly interest on the portion of the Customer's
				account overdue from time to time at the annual percentage rate of 5%
				per annum from the date on which the default arose; and/or<o:p></o:p
			></span>
		</p>

		<p
			class="MsoListParagraphCxSpLast"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				margin-left: 75pt;
				mso-add-space: auto;
				text-indent: -75pt;
				mso-text-indent-alt: -18pt;
				line-height: normal;
				mso-list: l25 level1 lfo11;
				background: white;
			"
		>
			<![if !supportLists]><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: Poppins;
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><span style="mso-list: Ignore"
					><span style="font: 7pt 'Times New Roman'"
						>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
					>II.<span style="font: 7pt 'Times New Roman'"
						>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span
				></span
			><![endif]><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>charge an administrative fee equal to an amount up to or equivalent
				to 5% of the amount of credit provided by the Company to the
				Customer.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<b
				><span
					style="
						font-size: 12pt;
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						color: #212529;
						mso-font-kerning: 0pt;
						mso-ligatures: none;
						mso-fareast-language: EN-AU;
					"
					>Customer's Responsibility</span
				></b
			><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>The Customer will ensure that the Company has uninterrupted access to
				the site at which the Services will be performed for the duration of
				the Services.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>The Customer acknowledges and agrees that:<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>The Company is only responsible for the removal of rubbish from the
				Services performed.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>If the Customer is to provide goods or other services for which the
				Services are required, then the Customer must have the goods available
				and site ready at least 24 hours before the time at which the Company
				requires to perform the relevant Services in respect of such other
				goods or services.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<b
				><span
					style="
						font-size: 12pt;
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						color: #212529;
						mso-font-kerning: 0pt;
						mso-ligatures: none;
						mso-fareast-language: EN-AU;
					"
					>Title and Risk</span
				></b
			><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>In relation to Goods supplied as part of the Services:<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>Title in those Goods will not pass to the Customer until the Price is
				paid in full.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>Risk in those Goods will pass to the Customer immediately upon
				delivery to or collection of those goods.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>Until the Company receives full payment of the Price, the Company has
				the right (without prejudice to any other rights and remedies it may
				have) to recover, remove and/or resell those Goods free from any
				claims by the Customer.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>The Customer will indemnify the Company against any costs, claims,
				<span class="GramE">damages</span> or losses suffered by the Company
				or a third party as a result of the Customer's inability to pay the
				Price for the Goods.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<b
				><span
					style="
						font-size: 12pt;
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						color: #212529;
						mso-font-kerning: 0pt;
						mso-ligatures: none;
						mso-fareast-language: EN-AU;
					"
					>Termination</span
				></b
			><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>Either party may terminate these Terms upon 48 hours' notice in
				writing to the other party.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>Either party may terminate these Terms immediately by written notice
				to the other if:<o:p></o:p
			></span>
		</p>

		<p
			class="MsoListParagraphCxSpFirst"
			style="
				mso-margin-bottom-alt: auto;
				mso-add-space: auto;
				text-indent: -36pt;
				mso-text-indent-alt: -18pt;
				line-height: normal;
				mso-list: l24 level1 lfo22;
				background: white;
			"
		>
			<![if !supportLists]><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: Poppins;
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><span style="mso-list: Ignore"
					><span style="font: 7pt 'Times New Roman'"
						>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
					>I.<span style="font: 7pt 'Times New Roman'"
						>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span
				></span
			><![endif]><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>the other party commits a material breach of these
				<span class="GramE">Terms</span> and that breach is not rectified
				within seven&nbsp;days of being notified; or<o:p></o:p
			></span>
		</p>

		<p
			class="MsoListParagraphCxSpLast"
			style="
				mso-margin-bottom-alt: auto;
				mso-add-space: auto;
				text-indent: -36pt;
				mso-text-indent-alt: -18pt;
				line-height: normal;
				mso-list: l24 level1 lfo22;
				background: white;
			"
		>
			<![if !supportLists]><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: Poppins;
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><span style="mso-list: Ignore"
					><span style="font: 7pt 'Times New Roman'"
						>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
					>II.<span style="font: 7pt 'Times New Roman'"
						>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span
				></span
			><![endif]><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>the other party becomes or is presumed insolvent or bankrupt.<o:p
				></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>In addition to giving notice to terminate the Agreement the Company
				may:<o:p></o:p
			></span>
		</p>

		<p
			class="MsoListParagraphCxSpFirst"
			style="
				mso-margin-bottom-alt: auto;
				mso-add-space: auto;
				text-indent: -36pt;
				mso-text-indent-alt: -18pt;
				line-height: normal;
				mso-list: l18 level1 lfo23;
				background: white;
			"
		>
			<![if !supportLists]><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: Poppins;
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><span style="mso-list: Ignore"
					><span style="font: 7pt 'Times New Roman'"
						>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
					>I.<span style="font: 7pt 'Times New Roman'"
						>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span
				></span
			><![endif]><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>retain any moneys paid by the Customer for Services already
				rendered.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoListParagraphCxSpMiddle"
			style="
				mso-margin-bottom-alt: auto;
				mso-add-space: auto;
				text-indent: -36pt;
				mso-text-indent-alt: -18pt;
				line-height: normal;
				mso-list: l18 level1 lfo23;
				background: white;
			"
		>
			<![if !supportLists]><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: Poppins;
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><span style="mso-list: Ignore"
					><span style="font: 7pt 'Times New Roman'"
						>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
					>II.<span style="font: 7pt 'Times New Roman'"
						>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span
				></span
			><![endif]><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>be regarded as discharged from any further obligations under these
				Terms; and<o:p></o:p
			></span>
		</p>

		<p
			class="MsoListParagraphCxSpLast"
			style="
				mso-margin-bottom-alt: auto;
				mso-add-space: auto;
				text-indent: -36pt;
				mso-text-indent-alt: -18pt;
				line-height: normal;
				mso-list: l18 level1 lfo23;
				background: white;
			"
		>
			<![if !supportLists]><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: Poppins;
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><span style="mso-list: Ignore"
					><span style="font: 7pt 'Times New Roman'"
						>&nbsp;&nbsp;&nbsp;&nbsp; </span
					>III.<span style="font: 7pt 'Times New Roman'"
						>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span
				></span
			><![endif]><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>pursue any additional or alternative remedies provided by law.<o:p
				></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<b
				><span
					style="
						font-size: 12pt;
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						color: #212529;
						mso-font-kerning: 0pt;
						mso-ligatures: none;
						mso-fareast-language: EN-AU;
					"
					>Warranty</span
				></b
			><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>To the extent required by statute, subject to the conditions of
				warranty as may be set out in&nbsp;<i
					>The Australian Consumer Law&nbsp;</i
				>located in&nbsp;<i
					>Schedule 2 of the Competition and Consumer Act 2010 (<span
						class="SpellE"
						>Cth</span
					>)</i
				>&nbsp;the Company warrants that if a defect in any of the Works
				carried out by the Company is reported to the Company within 12 months
				of the date of completion, then the Company will either replace or
				remedy the Works subject to these Terms and Conditions.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>This warranty will not be applicable in any of the following</span
			><span
				style="
					font-size: 13.5pt;
					font-family: 'Century Gothic', sans-serif;
					color: #004e96;
				"
				>:<o:p></o:p
			></span>
		</p>

		<p
			class="MsoListParagraphCxSpFirst"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				mso-add-space: auto;
				text-indent: -36pt;
				mso-text-indent-alt: -18pt;
				line-height: normal;
				mso-list: l10 level1 lfo28;
				background: white;
			"
		>
			<![if !supportLists]><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: Poppins;
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><span style="mso-list: Ignore"
					><span style="font: 7pt 'Times New Roman'"
						>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
					>I.<span style="font: 7pt 'Times New Roman'"
						>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span
				></span
			><![endif]><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>Defect or damage caused which may be caused or partly caused by or
				arising through the failure of the Customer to properly maintain any
				Works, follow any instructions or guidelines provided by
				<a name="_Hlk160550347">the Company</a>, use of any Works in any other
				way than is outlined in the quotation or by the Customer's
				negligence.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoListParagraphCxSpMiddle"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				mso-add-space: auto;
				text-indent: -36pt;
				mso-text-indent-alt: -18pt;
				line-height: normal;
				mso-list: l10 level1 lfo28;
				background: white;
			"
		>
			<![if !supportLists]><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: Poppins;
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><span style="mso-list: Ignore"
					><span style="font: 7pt 'Times New Roman'"
						>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
					>II.<span style="font: 7pt 'Times New Roman'"
						>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span
				></span
			><![endif]><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>The Customer continues to use any of the works after any defect
				becomes apparent or would have become apparent to a reasonably prudent
				operator or user.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoListParagraphCxSpMiddle"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				mso-add-space: auto;
				text-indent: -36pt;
				mso-text-indent-alt: -18pt;
				line-height: normal;
				mso-list: l10 level1 lfo28;
				background: white;
			"
		>
			<![if !supportLists]><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: Poppins;
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><span style="mso-list: Ignore"
					><span style="font: 7pt 'Times New Roman'"
						>&nbsp;&nbsp;&nbsp;&nbsp; </span
					>III.<span style="font: 7pt 'Times New Roman'"
						>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span
				></span
			><![endif]><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>The Customer allows someone other than a licensed professional to
				deal with the products.&nbsp;<o:p></o:p
			></span>
		</p>

		<p
			class="MsoListParagraphCxSpLast"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				mso-add-space: auto;
				text-indent: -36pt;
				mso-text-indent-alt: -18pt;
				line-height: normal;
				mso-list: l10 level1 lfo28;
				background: white;
			"
		>
			<![if !supportLists]><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: Poppins;
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><span style="mso-list: Ignore"
					><span style="font: 7pt 'Times New Roman'">&nbsp;&nbsp; </span
					>IV.<span style="font: 7pt 'Times New Roman'"
						>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span
				></span
			><![endif]><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>If the works fail due to fair wear and tear, any accident or act of
				God.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>The warranty shall <span class="GramE">cease</span> and the Company
				shall thereafter in no circumstances be liable under the terms of the
				warranty if the works are repaired, altered or overhauled without the
				Company's express written consent.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>In respect of all claims, the Company shall not be liable to
				compensate the Customer for any delay in either replacing or remedying
				the workmanship or in properly assessing the Customer's claim.<o:p
				></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>If the Company must
				<span class="GramE">make an inspection</span> outside of our premises,
				the Company may charge the Customer for all reasonable costs incurred
				including labour, travelling and other out-of-pocket expenses incurred
				if the fault or defect is deemed to be outside this warranty.<o:p
				></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>Where the Customer seeks to have defective goods replaced, the
				Company must have received notice within 6 months of the Customer
				receiving them and the goods must be returned to the Company within 12
				months of the Customer receiving them.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>Components and parts from third party suppliers are subject to the
				same warranty (if any) as is extended to the Company by the
				supplier.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>If the Company replaces defective goods, ownership in those defective
				goods transfers wholly and unconditionally to the Company.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>If a claim is made by a Customer and the Company, after assessing the
				item, deem this to be out of warranty, the Company will make
				reasonable attempts to contact the Customer to arrange for the items
				to be returned. If, after 30 days, the Customer has not
				<span class="GramE">made arrangements</span> to have the goods
				returned, the Company may dispose of the goods at the Customer's
				expense.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<b
				><span
					style="
						font-size: 12pt;
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						color: #212529;
						mso-font-kerning: 0pt;
						mso-ligatures: none;
						mso-fareast-language: EN-AU;
					"
					>Defects</span
				></b
			><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>If after completion of the Services the Customer considers that the
				Services are not in accordance with the Quotation or are defective in
				any way, the Customer must give the Company written notice together
				with reasons within seven days of completion of the Services, and
				unless such notice is given, the Services shall be deemed to comply
				with the Quotation in all respects.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>Upon receipt of a notice in accordance and subject to the Company
				being satisfied (acting reasonably) that the Services do not comply
				with the Quotation or are defective, the Company in its absolute
				discretion may elect to either:<o:p></o:p
			></span>
		</p>

		<p
			class="MsoListParagraphCxSpFirst"
			style="
				mso-margin-bottom-alt: auto;
				mso-add-space: auto;
				text-indent: -36pt;
				mso-text-indent-alt: -18pt;
				line-height: normal;
				mso-list: l21 level1 lfo25;
				background: white;
			"
		>
			<![if !supportLists]><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: Poppins;
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><span style="mso-list: Ignore"
					><span style="font: 7pt 'Times New Roman'"
						>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
					>I.<span style="font: 7pt 'Times New Roman'"
						>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span
				></span
			><![endif]><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>re-supply the non-conforming or defective Services
				<span class="GramE">or;</span><o:p></o:p
			></span>
		</p>

		<p
			class="MsoListParagraphCxSpLast"
			style="
				mso-margin-bottom-alt: auto;
				mso-add-space: auto;
				text-indent: -36pt;
				mso-text-indent-alt: -18pt;
				line-height: normal;
				mso-list: l21 level1 lfo25;
				background: white;
			"
		>
			<![if !supportLists]><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: Poppins;
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><span style="mso-list: Ignore"
					><span style="font: 7pt 'Times New Roman'"
						>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span
					>II.<span style="font: 7pt 'Times New Roman'"
						>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					</span></span
				></span
			><![endif]><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>provide the Customer with a refund or credit in respect of the
				non-conforming or defective Services.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<b
				><span
					style="
						font-size: 12pt;
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						color: #212529;
						mso-font-kerning: 0pt;
						mso-ligatures: none;
						mso-fareast-language: EN-AU;
					"
					>Variation</span
				></b
			><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>The Company may add or vary these Terms from time to time by
				uploading the varied Terms to the Website and notifying the Customer
				that the Terms have been varied.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<b
				><span
					style="
						font-size: 12pt;
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						color: #212529;
						mso-font-kerning: 0pt;
						mso-ligatures: none;
						mso-fareast-language: EN-AU;
					"
					>Sub-contract</span
				></b
			><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>The Company may sub-contract any or
				<span class="GramE">all of</span> its rights and obligations under
				these Terms without the Customer's consent.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<b
				><span
					style="
						font-size: 12pt;
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						color: #212529;
						mso-font-kerning: 0pt;
						mso-ligatures: none;
						mso-fareast-language: EN-AU;
					"
					>Governing law</span
				></b
			><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>These Terms are to be governed and interpreted in accordance with the
				laws of the State of New South Wales and the ACT. The parties agree to
				submit themselves to the non-exclusive jurisdiction of the courts of
				New South Wales and any competent appellate courts.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<b
				><span
					style="
						font-size: 12pt;
						font-family: Poppins;
						mso-fareast-font-family: 'Times New Roman';
						color: #212529;
						mso-font-kerning: 0pt;
						mso-ligatures: none;
						mso-fareast-language: EN-AU;
					"
					>Force Majeure</span
				></b
			><span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				>No party is liable for any failure to perform and delay in perform
				its obligations under these Terms if failure or delay is due to
				anything beyond that party's reasonable control.<o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><o:p>&nbsp;</o:p></span
			>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><o:p>&nbsp;</o:p></span
			>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><span style="mso-spacerun: yes">'</span><o:p></o:p
			></span>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><o:p>&nbsp;</o:p></span
			>
		</p>

		<p
			class="MsoNormal"
			style="
				mso-margin-top-alt: auto;
				mso-margin-bottom-alt: auto;
				line-height: normal;
				background: white;
			"
		>
			<span
				style="
					font-size: 12pt;
					font-family: Poppins;
					mso-fareast-font-family: 'Times New Roman';
					color: #212529;
					mso-font-kerning: 0pt;
					mso-ligatures: none;
					mso-fareast-language: EN-AU;
				"
				><o:p>&nbsp;</o:p></span
			>
		</p>

		<p style="margin-top: 0cm; background: white"><o:p>&nbsp;</o:p></p>
	</div>
</body>
</html>
`
