import m, * as bacta from "bacta"
import { Menu, MenuItem, MenuItemAttrs } from "../sl/menu.js"

type Attrs = {
	query?: bacta.Store<string> | bacta.Store<object>
	options: Array<string | object>
	nameProp: string
}

type BaseAttrs = {
	sl?: Partial<MenuItemAttrs>
	name?: string
}

export type MainAttrs = BaseAttrs & Attrs

export const HarthMenuItem: bacta.ClosureComponent<BaseAttrs> = () => ({
	view: ({ attrs: { sl = {}, ...attrs } }) => {
		return m(
			MenuItem,
			{
				value: sl.value,
			},
			attrs.name
		)
	},
})

export const HarthMenu: bacta.ClosureComponent<MainAttrs> = () => ({
	view: ({ attrs: { sl = {}, ...attrs } }) => {
		return m(
			Menu,
			{
				["onsl-select"](e: any) {
					attrs?.query?.update(() => e.valueOf().detail.item.value)
				},
			},
			attrs?.options.map((o: any) =>
				m(HarthMenuItem, {
					sl: { value: o },
					name: attrs.nameProp ? o[attrs.nameProp] : o,
				} as BaseAttrs)
			)
		)
	},
})
