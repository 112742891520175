import m, * as bacta from "bacta"
import "@shoelace-style/shoelace/dist/themes/light.css"
import "@shoelace-style/shoelace/dist/themes/dark.css"
import "@shoelace-style/shoelace/dist/components/option/option.js"

export type OptionAttrs = {
	value?: string
	disabled?: boolean
	// "onsl-label-change"?(event: InputEvent): void;
}
export const Option: bacta.ClosureComponent<OptionAttrs> = () => ({
	view: ({ attrs = {}, children }) => m("sl-option", attrs, children),
})
