import m, * as bacta from "bacta"
import "@shoelace-style/shoelace/dist/themes/light.css"
import "@shoelace-style/shoelace/dist/themes/dark.css"
import "@shoelace-style/shoelace/dist/components/input/input.js"
import css from "../../main.module.css"

export type InputAttrs = {
	type?:
		| "date"
		| "datetime-local"
		| "email"
		| "number"
		| "password"
		| "search"
		| "tel"
		| "text"
		| "time"
		| "url"
	size?: "small" | "medium" | "large"
	name?: string
	value?: string
	label?: string
	clearable?: boolean
	disabled?: boolean
	defaultValue?: string
	placeholder?: string
	helpText?: string
	class?: string
	pattern?: string
	pill?: boolean
	min?: number | string
	max?: number | string
	step?: number
	noSpinButtons?: boolean
	form?: string
	id?: string
	autocomplete?:
		| "on"
		| "off"
		| "name"
		| "street-address"
		| "tel"
		| "email"
		| "tel-local"
	inputmode?:
		| "none"
		| "text"
		| "decimal"
		| "numeric"
		| "tel"
		| "search"
		| "email"
		| "url"
	required?: boolean
	"onsl-input"?(event: InputEvent): void
}
export const Input: bacta.ClosureComponent<InputAttrs> = () => ({
	view: ({ attrs = { class: "" }, children }) =>
		m("sl-input." + css[attrs.class || ""], attrs, children),
})
