import "@shoelace-style/shoelace/dist/themes/light.css"
import "@shoelace-style/shoelace/dist/themes/dark.css"
import "@shoelace-style/shoelace/dist/components/checkbox/checkbox.js"
import css from "../../main.module.css"

import m, * as bacta from "bacta"
export type CheckboxAttrs = {
	name?: string
	value?: boolean | null
	disabled?: boolean
	size?: "small" | "medium" | "large"
	checked?: boolean
	required?: boolean
	defaultChecked?: boolean
	class?: string

	"onsl-change"?(event: InputEvent): void
}
export const Checkbox: bacta.ClosureComponent<CheckboxAttrs> = () => ({
	view: ({ attrs = {}, children = [] }) => {
		return m("sl-checkbox." + css[attrs.class || ""], attrs, children)
	},
})
