/* eslint-disable no-mixed-spaces-and-tabs */
/* globals console, setTimeout */
import m, * as bacta from "bacta"
import * as R from "ramda"
import Uppy from "./uppy"
import * as uuid from "uuid"
import moment from "moment"
import legacyCSS from "../../main.module.css"
const css = bacta.css

function diffFields(a: any, b: any) {
	return !R.equals(a, b)
}

async function request(url: string, para: any): Promise<any> {
	return m.request<any[]>(url, para).then(
		(res: any) => {
			// console.log(res)
			return res
		},
		(e: any) => {
			console.log(e.response)
			return e.response
		}
	)
}
const EVGEN_ORG_ID = "34d166ee-4c95-429c-ba5e-21d079449b2a"

const data = {
	api: {
		files: {
			async SIGN(filename: any, filetype: any, associations: any) {
				return await request("/api/odin/signfiles", {
					method: "POST",
					headers: {
						"content-type": "application/json",
					},
					body: {
						...associations,
						filename,
						filetype,
					},
				})
			},
			async getTransLoaditSignature(body: any) {
				return await request("/api/odin/gettranssignature", {
					method: "POST",
					headers: {
						"content-type": "application/json",
					},
					body,
				})
			},
			async LIST(body: any) {
				return await request("/api/odin/getfiles", {
					method: "POST",
					headers: {
						"content-type": "application/json",
					},
					body,
				})
			},

			async DELETE(files: any) {
				return await request("/api/odin/deletefiles", {
					method: "POST",
					headers: {
						"content-type": "application/json",
					},
					body: { files: files.map((f: any) => f.file_id) },
				})
			},

			async UNDELETE(files: any) {
				return await request("/api/odin/undeletefiles", {
					method: "POST",
					headers: {
						"content-type": "application/json",
					},
					body: { files: files.map((f: any) => f.file_id) },
				})
			},
		},
	},
	features: () => ({
		uppyWebcam: true,
		uppyGoogleDrive: false,
		uppyDropbox: false,
		uppy: true,
	}),
}
const hideScrollbars = css` & {
		-ms-overflow-style: none;
		scrollbar-width: none;
		& ::-webkit-scrollbar" {
			display: none;
		}
	}
  `
const image_exts = ["jpg", "jpeg", "png", "gif"]

/**
 * files = List of Harth files
 * data = Harth data object, for API access
 * embedded = Do not create the white area
 * listOnly = Do not show the uploader
 * readOnly = No Delete Action
 */
function MiniGallery() {
	return {
		view({
			attrs: { files, data, listOnly, readOnly, embedded, download },
		}: any) {
			return m(
				".mini-gallery",
				// hideScrollbars,
				embedded ? ".embedded" : "",
				css`
					& {
						display: flex;
						flex-wrap: wrap;
						--width: 5.7em;
						background-color: #fafafa;
						padding: 0.5em;
						border-radius: 0.5em;
						border: 1px solid #eaeaea;
						gap: 0.3em;
						opacity: 1;
						cursor: default;

						max-height: 18.1em;
						overflow-x: auto;
					}
				`,
				css`
					&.embedded {
						background-color: transparent;
						padding: 0em;
						border: none;
					}
				`,
				css`
					&.embedded .file {
						margin: 0em;
						border: none;
					}
				`,
				// .$animate("ease-in-out 1s 1s forwards", {
				// 	from: "o 0",
				// 	to: "o 1",
				// })
				css`
					& .file {
						width: var(--width);
						height: var(--width);
						min-height: var(--width);
						background-size: cover;
						transition: 0.2s;
						border: solid 1px #eee;
						background-color: #eee;
						border-radius: 0.5em;
						opacity: 1;
						position: relative;

						margin: ${listOnly ? "0.5em" : "0em"};
					}
				`,
				css`
					& * {
						cursor: pointer;
					}
				`,
				// .$animate("ease-in-out 1s 2s forwards", {
				// 	from: "o 0%",
				// 	to: "o 100%",
				// })
				css`
					&:hover {
						opacity: 0.9;
					}
				`,
				css`
					&:hover {
						opacity: 0.9;
					}
				`,
				css`
					@media (hover: hover) {
						& {
							transform: scale(0.95);
						}
					}
				`,
				css`
					& .actions {
						animation-duration: 0.2s;
						animation-name: slidein;
					}
				`,
				css`
					& .action.disabled:hover {
						text-decoration: none;
					}
				`,
				css`
					&&& .file:hover .ext {
						opacity: 0;
						animation: none;
					}
				`,
				(download && files.length
					? [
							{
								zipRequest: true,
								file_ext: download.reference.zip_error
									? "Error"
									: download.reference.is_zip_processing
									? "Processing"
									: Date.now() -
											new Date(download.reference.latest_zip_date).getTime() <
									  60 * 60 * 1000
									? "Completed"
									: "",
								file_date: moment(download.reference.latest_zip_date).fromNow(),
								file_url: download.reference.latest_zip_url,

								file_thumbnail_url: "../assets/icons8-download.gif",
								file_thumb_url: "../assets/icons8-download.gif",
								mithrilKey: "zip-indicator",
								file_id: null as null | string,
								file_deleted_at: null as null | number | undefined,
								_loading: null as null | boolean | number,
							},
					  ]
					: []
				)
					.concat(files)
					.map((x) =>
						m(
							".",
							css`
								& {
									display: grid;
									grid-template-rows: auto;
								}
							`,
							m(
								"a.file" + "." + x.file_id,
								{
									style: {
										backgroundImage: !image_exts.includes(x.file_ext)
											? `url("${x.file_thumbnail_url}")`
											: `url("${x.file_thumb_url}")`,
									},
									key: (x.mithrilKey || x.file_id || x.file_url) + "pic",
									href: x.zipRequest
										? x.file_url
										: `https://odin.harth.io/data/files/${EVGEN_ORG_ID}/view/${x.file_id}`,
									target: "_blank",
									rel: "noopener noreferrer",
									title: "Created " + x.file_date,
									disabled:
										x.file_deleted_at != null ||
										!x.zipRequest ||
										(x.zipRequest && x.file_url),
								},
								m(
									".overlay",
									css`
										& {
											min-width: 100%;
											min-height: 100%;
											background-color: black;
											display: block;
											position: absolute;
											border-radius: 0.5em;
											top: 0px;
											pointer-events: none;
											opacity: ${x.file_deleted_at != null ? 0.8 : 0};
											transition: 2s;
										}
									`
								),
								x.zipRequest &&
									["Processing", "Completed", "Error"].includes(x.file_ext)
									? m(
											".ext",
											css`
												& {
													position: relative;
													top: -4px;
													color: white;
													background-color: ${x.file_ext == "Error"
														? "red"
														: "black"};
													border-radius: 0.5em;
													padding: 0.2em 0.5em;
													opacity: 1;
													animation: bdcmli51 ease-in-out 1s forwards;
													text-align: center;
													font-size: 13px;
													height: 2em;
													align-content: center;
													display: grid;
												}
											`,
											// .$animate("ease-in-out 1s forwards", {
											// 	from: "o 0",
											// 	to: "o 1",
											// }),
											x.file_ext
									  )
									: (!image_exts.includes(x.file_ext) || x.file_deleted_at) &&
											!x.zipRequest &&
											m(
												".ext",
												css`
													& {
														position: relative;
														left: -4px;
														top: -4px;
														color: white;
														background-color: black;
														border-radius: 0.5em;
														padding: 0.2em 0.5em;
														opacity: 1;
													}
												`,
												// .$animate("ease-in-out 1s forwards", {
												// 	from: "o 0",
												// 	to: "o 1",
												// })
												x.file_deleted_at ? "Deleted" : x.file_ext
											)
							),
							m(
								".actions",
								{
									key: (x.mithrilKey || x.file_id || x.file_url) + "actions",
								},
								css` & {
									--position: absolute;
									--top: 0px;
									background-color: rgba(0,0,0,0.8)
									grid-template-rows: ${x.file_deleted_at || readOnly ? `1fr` : `1fr 1fr`};
									gap: 0.1em;
									height: 100%;
									width: 100%;
									align-content: space-between;
									justify-content: center;
									--align-items: end;
									display: grid;
									border-radius: 0.5em;
									opacity: 1;
									transition: 0.2s;
									pointer-events: none;
								}
							`,

								css`
									& .action {
										color: black;
										opacity: 1;
										justify-content: center;
										align-items: center;
										display: grid;
										transition: 0.2s;
									}
								`,
								css`
									& .action:hover {
										color: black
										text-decoration: 										
										color: ${
											(x.zipRequest &&
												download.reference.is_zip_processing == "Processing") ||
											(x.zipRequest &&
												download.reference.zip_error == "Retry?") ||
											(x.zipRequest && x.file_url == "New Zip") ||
											x.zipRequest
												? "underline"
												: "none"
										};
										opacity: 1;
									}
								`,
								// x.file_deleted_at != null ||
								// 	((!x.zipRequest || (x.zipRequest && x.file_url)) &&
								// 		m(
								// 			"a.action",
								// 			{
								// 				href: x.zipRequest
								// 					? x.file_url
								// 					: `https://odin.harth.io/data/files/${EVGEN_ORG_ID}/view/${x.file_id}`,
								// 				target: "_blank",
								// 				rel: "noopener noreferrer",
								// 				title: "Created " + x.file_date,
								// 			},
								// 			css`
								// 				& {
								// 					color: white;
								// 					text-decoration: none;
								// 					pointer-events: all;

								// 					& visited {
								// 						color: white;
								// 					}
								// 				}
								// 			`,
								// 			x.zipRequest && x.file_url
								// 				? Date.now() -
								// 						new Date(
								// 							download.reference.latest_zip_date
								// 						).getTime() <
								// 				  30_000
								// 					? "Latest"
								// 					: "Previous"
								// 				: "View"
								// 		)),
								x.file_deleted_at != null ||
									(!readOnly &&
										(!x.zipRequest || x.file_id != "Processing") &&
										m(
											".action",
											{
												onclick:
													download && download.reference.is_zip_processing
														? null
														: x.zipRequest
														? async () => {
																download.reference.is_zip_processing = true
																m.redraw()
																await download.request()
																m.redraw()
														  }
														: () => {
																x._loading = Date.now()
																return data.api.files
																	.DELETE([x])
																	.then(() => (x.file_deleted_at = Date.now()))
																	.finally(() => (x._loading = false))
																	.finally(() => m.redraw())
														  },
												disabled: x._loading,
											},
											css`
												& {
													pointer-events: all;
												}
											`,
											css`
												& {
													${download && download.reference.is_zip_processing
														? ".disabled"
														: ""}
												}
											`,
											x.zipRequest && download.reference.is_zip_processing
												? "Processing"
												: x.zipRequest && download.reference.zip_error
												? "Retry?"
												: x.zipRequest && x.file_url
												? "New Zip"
												: x.zipRequest
												? "Create Zip"
												: m("." + legacyCSS.cross)
										)),
								x.file_deleted_at != null &&
									!readOnly &&
									m(
										".action",
										{
											onclick() {
												x._loading = Date.now()
												data.api.files
													.UNDELETE([x])
													.then(() => delete x.file_deleted_at)
													.finally(() => (x._loading = false))
													.finally(() => m.redraw())
											},
											disabled: x._loading,
										},
										css`
											& {
												pointer-events: all;
											}
										`,
										"Restore"
									)
							)
						)
					)
			)
		},
	}
}

function Main({ attrs }: any) {
	let prevFields = attrs.getFields()
	let loading = true
	let files: string | any[] = []
	let uppy: {
		// eslint-disable-next-line @typescript-eslint/ban-types
		setState: (arg0: { files: {} | {} }) => any
		on: (arg0: string, arg1: () => void) => void
	}
	const query = attrs.query
	const syncFiles = (fks: any) => {
		return data.api.files
			.LIST(fks)
			.then(
				(xs: string | any[]) => (files = xs) && query.update(() => xs),
				console.error
			)
			.finally(() => (loading = false))
			.then(() => m.redraw())
	}

	syncFiles(prevFields)

	function view({ attrs }: any) {
		const { getFields, listOnly, width = 300, height = 250, download } = attrs

		const nextFields = getFields()

		const fieldsChanged = diffFields(prevFields, nextFields)

		if (!loading && fieldsChanged) {
			// re-fetch list, reset uppy, clear deleted files
			files = []
			syncFiles(nextFields).then(() => uppy && uppy.setState({ files: {} }))
		}
		prevFields = nextFields

		return m(
			".harth-uppy",
			{
				style: {
					"justify-items": "center",
					display: "grid",
					gap: "1em",
				},
			},
			listOnly ||
				Uppy.simple({
					v: attrs.v,
					width,
					height,
					getFields() {
						return Object.assign({ file_id: uuid.v4() }, prevFields)
					},
					instance: (_uppy: {
						// eslint-disable-next-line @typescript-eslint/ban-types
						setState: (arg0: { files: {} }) => any
						on: (arg0: string, arg1: () => void) => void
					}) => {
						uppy = _uppy
						uppy.on("complete", () => {
							// give the harth API time to receive the notification
							const NOTIFY_TIMEOUT = 3000

							setTimeout(() => {
								syncFiles(prevFields).then(() => uppy.setState({ files: {} }))
							}, NOTIFY_TIMEOUT)
						})
					},
					data,
				}),
			files.length > 0 &&
				m(MiniGallery, {
					files,
					loading,
					data,
					listOnly,
					embedded: attrs.embedded,
					readOnly: attrs.readOnly,
					download,
				})
		)
	}

	return { view }
}

export default Main
